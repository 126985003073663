import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/authService'; // Lógica de autenticación
import { startTokenExpirationTimer } from '../utils/authToken'; // Manejo de expiración del token
import CustomAlert from '../components/CustomAlert';

const Login = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    password: '',
    rememberMe: false,
    showPassword: false
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  const navigate = useNavigate();

  useEffect(() => {
    const savedNombre = localStorage.getItem('nombre');
    const savedPassword = localStorage.getItem('password');

    if (savedNombre) setFormData(prevState => ({ ...prevState, nombre: savedNombre }));
    if (savedPassword) setFormData(prevState => ({ ...prevState, password: savedPassword }));
  }, []);

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  const showAlert = (message, severity) => {
    setAlert({
      open: true,
      message,
      severity
    });
  };

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { nombre, password } = formData;
      const userData = await loginUser({ nombre: nombre.trim(), password: password.trim() });

      if (userData?.token) {
        // Iniciar temporizador para la expiración del token
        startTokenExpirationTimer();

        // Guardar otros datos en localStorage
        localStorage.setItem('nombre', userData.nombre);
        localStorage.setItem('role', userData.role);
        localStorage.setItem('userId', userData.id);

        if (formData.rememberMe) {
          localStorage.setItem('password', password);
        } else {
          localStorage.removeItem('password');
        }

        showAlert('Inicio de sesión exitoso', 'success');
        setTimeout(() => navigate('/dashboard'), 1000);
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);

      const errorMessage = error.response?.data?.error || 'Error al iniciar sesión. Intenta nuevamente.';
      showAlert(errorMessage, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-600 to-blue-800 flex items-center justify-center p-4">
      <CustomAlert
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleCloseAlert}
      />
      <div className="flex w-full max-w-6xl">
        {/* Panel izquierdo */}
        <div className="hidden md:flex md:w-1/2 bg-white/10 backdrop-blur-md p-8 rounded-l-2xl flex-col justify-between">
          <div>
            <h1 className="text-4xl font-bold text-white mb-4">NOVATECH</h1>
            <p className="text-white/90 text-xl mb-8">Sistema de Gestión Empresarial</p>
          </div>
          <div className="space-y-4">
            <p className="text-white/80 text-sm">
              ⚠️ ADVERTENCIA: Este es un sistema de uso restringido.
            </p>
            <p className="text-white/80 text-sm">
              El acceso no autorizado está prohibido y será penado conforme a las leyes vigentes.
            </p>
          </div>
        </div>

        {/* Panel de login */}
        <div className="w-full md:w-1/2 bg-white/95 backdrop-blur-sm p-8 rounded-2xl md:rounded-l-none md:rounded-r-2xl shadow-2xl transform transition-all duration-300 hover:shadow-3xl">
          <div className="text-center mb-8">
            <h2 className="text-4xl font-extrabold text-gray-800 mb-2">¡Bienvenido!</h2>
            <p className="text-gray-600">Inicia sesión en tu cuenta</p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">
                  Usuario
                </label>
                <input
                  id="nombre"
                  type="text"
                  value={formData.nombre}
                  onChange={handleChange}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                  placeholder="Ingresa tu usuario"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                  Contraseña
                </label>
                <div className="relative">
                  <input
                    id="password"
                    type={formData.showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange}
                    className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all"
                    placeholder="Ingresa tu contraseña"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setFormData(prevState => ({ ...prevState, showPassword: !prevState.showPassword }))}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    {formData.showPassword ? "Ocultar" : "Mostrar"}
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={formData.rememberMe}
                  onChange={handleChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded transition-all"
                />
                <span className="ml-2 text-sm text-gray-600">Recordarme</span>
              </label>
            </div>
            <button
              type="submit"
              disabled={loading}
              className={`w-full bg-gradient-to-r from-blue-600 to-blue-800 text-white py-3 px-4 rounded-lg font-medium hover:from-blue-700 hover:to-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transform transition-all ${
                loading ? 'opacity-50 cursor-not-allowed' : 'hover:-translate-y-0.5 hover:shadow-lg'
              }`}
            >
              {loading ? 'Iniciando sesión...' : 'Iniciar sesión'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;