import React, { useEffect, useState } from 'react';
import CustomAlert from '../components/CustomAlert';
import { api } from '../config/apiConfig';

const Apu = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCrearApu, setShowCrearApu] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentApu, setCurrentApu] = useState({
        nombre: '',
        descripcion: '',
        idUnidadMedida: '',
        precio: 0,
        estado: 1
    });
    const [resources, setResources] = useState([]);
    const [selectedResources, setSelectedResources] = useState([]);
    const [units, setUnits] = useState([]);
    const [apuResources, setApuResources] = useState([]);
    const [showResources, setShowResources] = useState(false);
    const [selectedApuResources, setSelectedApuResources] = useState([]);
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'info'
    });
    const darkMode = localStorage.getItem('darkMode') !== 'false';
    const userRole = localStorage.getItem('role');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [apuResponse, resourceResponse, unitResponse] = await Promise.all([
                api.get('/apu'),
                api.get('/recurso'),
                api.get('/unidadMedida')
            ]);

            const apusConEstado = apuResponse.data.map(apu => ({
                ...apu,
                estado: apu.estado === null ? 1 : Number(apu.estado)
            }));

            setData(apusConEstado);
            setResources(resourceResponse.data);
            setUnits(unitResponse.data);
        } catch (error) {
            setError('¡Hubo un error al obtener los datos!');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleToggleStatus = async (apu) => {
        try {
            const newEstado = Number(apu.estado) === 1 ? 0 : 1;
            await api.put(`/apu/${currentApu.id}`, {
                ...apu,
                estado: newEstado,
                updatedAt: new Date().toISOString()
            });
            
            setData(prevData => prevData.map(item => 
                item.id === apu.id ? {...item, estado: newEstado} : item
            ));
            
            showAlert(`APU ${newEstado === 1 ? 'habilitado' : 'deshabilitado'} exitosamente`, 'success');
        } catch (error) {
            showAlert('Error al cambiar el estado del APU', 'error');
            console.error('Error:', error);
        }
    };

    const handleEdit = async (apu) => {
        if (Number(apu.estado) === 0) {
            showAlert('No se puede editar un APU inactivo', 'error');
            return;
        }
        
        setCurrentApu(apu);
        setShowCrearApu(true);
        try {
            const response = await api.get(`/apuRecurso`);
            const apuRecursos = response.data.filter(ar => ar.id_apu === apu.id);
            
            const resourceDetails = await Promise.all(
                apuRecursos.map(async (apuRecurso) => {
                    const resourceResponse = await api.get(`/recurso/${apuRecurso.id_recurso}`);
                    return {
                        ...resourceResponse.data,
                        precio: apuRecurso.precio,
                        apuRecursoId: apuRecurso.id,
                        idUnidadMedida: apuRecurso.idUnidadMedida
                    };
                })
            );
            
            setSelectedResources(resourceDetails.map(resource => ({
                id: resource.id,
                precio: resource.precio,
                apuRecursoId: resource.apuRecursoId,
                idUnidadMedida: resource.idUnidadMedida
            })));
            
            setApuResources(resourceDetails);
        } catch (error) {
            console.error('Error al obtener recursos del APU:', error);
            setSelectedResources([]);
            setApuResources([]);
        }
    };


    const fetchApuResources = async (apuId) => {
        try {
            const response = await api.get(`/apuRecurso`);
            const apuRecursos = response.data.filter(ar => ar.id_apu === apuId);
            
            const resourceDetails = await Promise.all(
                apuRecursos.map(async (apuRecurso) => {
                    const resourceResponse = await api.get(`/recurso/${apuRecurso.id_recurso}`);
                    const unitResponse = await api.get(`/unidadMedida/${apuRecurso.idUnidadMedida}`);
                    return {
                        ...resourceResponse.data,
                        precio: apuRecurso.precio,
                        unidadMedida: unitResponse.data.descripcion
                    };
                })
            );
            
            setSelectedApuResources(resourceDetails);
            setShowResources(true);
        } catch (error) {
            console.error('Error al obtener recursos del APU:', error);
        }
    };

    const handleCreate = () => {
        setCurrentApu({
            nombre: '',
            descripcion: '',
            idUnidadMedida: '',
            precio: 0,
            estado: 1
        });
        setShowCrearApu(true);
        setSelectedResources([]);
        setApuResources([]);
    };

    const handleClose = () => {
        setShowCrearApu(false);
        setCurrentApu(null);
        setSelectedResources([]);
        setApuResources([]);
        setShowResources(false);
    };

    const saveApuResources = async (apuId) => {
        try {
            await Promise.all(selectedResources.map(resource => 
                api.post(`/apuRecurso`, {
                    id_apu: apuId,
                    id_recurso: resource.id,
                    precio: resource.precio,
                    idUnidadMedida: resource.idUnidadMedida,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                })
            ));
        } catch (error) {
            console.error('Error al guardar los recursos del APU:', error);
            throw error;
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            if (!currentApu.nombre || !currentApu.descripcion) {
                showAlert('Nombre y descripción son requeridos', 'error');
                return;
            }

            if (selectedResources.some(r => !r.id)) {
                showAlert('Todos los recursos deben estar seleccionados', 'error');
                return;
            }

            if (!currentApu.id) {
                const { data: newApu } = await api.post(`/apu`, {
                    ...currentApu,
                    estado: 1,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                });

                await saveApuResources(newApu.id);

                setData(prevData => [...prevData, newApu]);
                showAlert('APU creado exitosamente', 'success');
            } else {
                const currentApuData = data.find(a => a.id === currentApu.id);
                
                await api.put(`/apu/${currentApu.id}`, {
                    ...currentApu,
                    estado: currentApuData.estado,
                    updatedAt: new Date().toISOString()
                });

                const { data: currentResources } = await api.get(`/apuRecurso`);
                const apuRecursos = currentResources.filter(ar => ar.id_apu === currentApu.id);

                await Promise.all(
                    apuRecursos.map(async (apuRecurso) => {
                        await api.delete(`/apuRecurso/${apuRecurso.id}`);
                    })
                );

                await saveApuResources(currentApu.id);

                const { data: updatedApus } = await api.get(`/apu`);
                setData(updatedApus);
                showAlert('APU actualizado exitosamente', 'success');
            }

            handleClose();
            await fetchData();
            showAlert(currentApu.id ? 'APU actualizado exitosamente' : 'APU creado exitosamente', 'success');
            
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error al guardar los datos';
            showAlert(errorMessage, 'error');
            console.error('Error:', error);
            console.error(data);
        }
    };

    const handleResourceSelect = (index, resourceId) => {
        const selectedResource = resources.find(r => r.id === resourceId);
        const updatedResources = [...selectedResources];
        updatedResources[index] = {
            ...updatedResources[index],
            id: resourceId,
            precio: selectedResource?.precio || 0
        };
        setSelectedResources(updatedResources);
        updateApuPrice(updatedResources);
    };

    const handleResourceUnitSelect = (index, unitId) => {
        const updatedResources = [...selectedResources];
        updatedResources[index] = {
            ...updatedResources[index],
            idUnidadMedida: unitId
        };
        setSelectedResources(updatedResources);
    };

    const handleAddResource = () => {
        setSelectedResources([...selectedResources, { id: '', precio: 0, idUnidadMedida: '' }]);
    };

    const handleRemoveResource = (index) => {
        const updatedResources = selectedResources.filter((_, i) => i !== index);
        setSelectedResources(updatedResources);
        updateApuPrice(updatedResources);
    };

    const updateApuPrice = (resources) => {
        const total = resources.reduce((sum, resource) => sum + (resource.precio || 0), 0);
        setCurrentApu(prev => ({ ...prev, precio: total }));
    };

    const filteredData = data.filter(apu => {
        const unidadMedida = units.find(unit => unit.id === apu.idUnidadMedida);
        const searchString = searchTerm.toLowerCase();
        return (
            apu.nombre?.toLowerCase().includes(searchString) ||
            apu.descripcion?.toLowerCase().includes(searchString) ||
            unidadMedida?.descripcion.toLowerCase().includes(searchString)
        );
    });

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const showAlert = (message, severity) => {
        setAlert({
            open: true,
            message,
            severity
        });
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-green-500"></div>
                <span className="ml-3 text-xl font-semibold">Cargando...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 m-4 rounded">
                <p className="font-bold">Error</p>
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-6">
                <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                    Lista de APUs
                </h1>
                <div className="flex space-x-4">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Buscar APU..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-64 pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-150 ease-in-out text-black"
                        />
                        <svg className="w-5 h-5 text-gray-400 absolute left-3 top-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                        </svg>
                    </div>
                    <button 
                        onClick={handleCreate}
                        className="w-12 h-12 rounded-full bg-green-600 hover:bg-green-700 text-white flex items-center justify-center shadow-lg transition duration-200 ease-in-out"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                        </svg>
                    </button>
                </div>
            </div>

            <div className="bg-white rounded-xl shadow-xl overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full table-auto">
                        <thead>
                            <tr className="bg-gradient-to-r from-blue-800 to-blue-900 text-white">
                                <th className="px-4 py-3 text-left font-semibold w-1/5">Nombre</th>
                                <th className="px-4 py-3 text-left font-semibold w-2/5">Descripción</th>
                                <th className="px-4 py-3 text-left font-semibold w-1/5">Precio</th>
                                <th className="px-4 py-3 text-left font-semibold w-1/5">Unidad de Medida</th>
                                <th className="px-4 py-3 text-center font-semibold w-1/5">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {filteredData.length > 0 ? (
                                filteredData.map((apu) => (
                                    <tr key={apu.id} className="hover:bg-gray-50 transition-colors duration-200 text-black">
                                        <td className="px-4 py-3 whitespace-nowrap">{apu.nombre}</td>
                                        <td className="px-4 py-3">{apu.descripcion}</td>
                                        <td className="px-4 py-3">${apu.precio.toLocaleString('es-CL')}</td>
                                        <td className="px-4 py-3">
                                            {units.find(unit => unit.id === apu.idUnidadMedida)?.nombre || 'Unidad no encontrada'}
                                        </td>
                                        <td className="px-4 py-3">
                                            <div className="flex justify-center space-x-2 items-center">
                                                <button
                                                    onClick={() => fetchApuResources(apu.id)}
                                                    className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors duration-300"
                                                >
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                                    </svg>
                                                </button>
                                                { userRole === 'Administrador' && (
                                                    <>
                                                        <button 
                                                            onClick={() => handleEdit(apu)}
                                                            className={`px-4 py-2 rounded-lg ${Number(apu.estado) === 1 ? 'bg-cyan-500 hover:bg-cyan-600' : 'bg-gray-400'} text-white transition-colors duration-300`}
                                                        >
                                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                                            </svg>
                                                        </button>
                                                        <label className="relative inline-flex items-center cursor-pointer">
                                                            <input 
                                                                type="checkbox" 
                                                                className="sr-only peer"
                                                                checked={Number(apu.estado) === 1}
                                                                onChange={() => handleToggleStatus(apu)}
                                                            />
                                                            <div className={`w-14 h-7 rounded-full transition-colors duration-300 ease-in-out ${Number(apu.estado) === 1 ? 'bg-green-500' : 'bg-gray-300'}`}>
                                                                <div className={`absolute w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${Number(apu.estado) === 1 ? 'translate-x-8' : 'translate-x-1'} top-1`}></div>
                                                            </div>
                                                        </label>
                                                    </>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="px-4 py-6 text-center text-gray-500">
                                        {searchTerm ? 'No se encontraron resultados para la búsqueda' : 'No hay datos disponibles'}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {showResources && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white rounded-xl shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-hidden">
                        <div className="p-6 border-b border-gray-200">
                            <div className="flex justify-between items-center">
                                <h2 className="text-2xl font-bold text-gray-800">Recursos Asociados</h2>
                                <button 
                                    onClick={() => setShowResources(false)}
                                    className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="p-6">
                            <div className="overflow-x-auto">
                                {selectedApuResources.length > 0 ? (
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Descripción</th>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Precio</th>
                                                <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unidad de Medida</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {selectedApuResources.map((resource, index) => (
                                                <tr key={index} className="hover:bg-gray-50 text-black">
                                                    <td className="px-4 py-3 whitespace-nowrap">{resource.nombre}</td>
                                                    <td className="px-4 py-3">{resource.descripcion}</td>
                                                    <td className="px-4 py-3">${resource.precio}</td>
                                                    <td className="px-4 py-3">{resource.unidadMedida}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p className="text-center py-4 text-gray-500">No hay recursos asociados</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showCrearApu && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white rounded-xl shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                        <div className="p-6 border-b border-gray-200">
                            <div className="flex justify-between items-center">
                                <h2 className="text-2xl font-bold text-gray-800">
                                    {currentApu.id ? 'Editar APU' : 'Crear APU'}
                                </h2>
                                <button 
                                    onClick={handleClose}
                                    className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                                >
                                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <form onSubmit={handleSave} className="p-6 space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Nombre</label>
                                <input
                                    type="text"
                                    value={currentApu.nombre}
                                    onChange={(e) => setCurrentApu({ ...currentApu, nombre: e.target.value })}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200 text-black"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Descripción</label>
                                <textarea
                                    value={currentApu.descripcion}
                                    onChange={(e) => setCurrentApu({ ...currentApu, descripcion: e.target.value })}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200 min-h-[100px] text-black"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Precio</label>
                                <input
                                    type="number"
                                    value={selectedResources.reduce((total, resource) => total + (resource.precio || 0), 0)}
                                    readOnly
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200 text-black"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Unidad de Medida</label>
                                <select
                                    value={currentApu.idUnidadMedida}
                                    onChange={(e) => setCurrentApu({ ...currentApu, idUnidadMedida: e.target.value })}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200 text-black"
                                    required
                                >
                                    <option value="">Seleccione una unidad de medida</option>
                                    {units.map(unit => (
                                        <option key={unit.id} value={unit.id}>
                                            {unit.descripcion}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-4">
                                <h3 className="text-lg font-semibold text-gray-800">Recursos</h3>
                                {selectedResources.map((resource, index) => (                                    
                                    <div key={index} className="flex items-center gap-4">
                                        <select
                                            value={resource.id}
                                            onChange={(e) => handleResourceSelect(index, e.target.value)}
                                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200 text-black"
                                        >
                                            <option value="">Seleccione un recurso</option>
                                            {resources.map(res => (
                                                <option key={res.id} value={res.id}>
                                                    {res.nombre}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="flex flex-col w-full">
                                            <label className="block text-sm font-medium text-gray-700 mb-2">Precio del Recurso</label>
                                            <input
                                                type="number"
                                                value={resource.precio}
                                                onChange={(e) => {
                                                    const updatedResources = [...selectedResources];
                                                    updatedResources[index].precio = parseFloat(e.target.value) || 0;
                                                    setSelectedResources(updatedResources);
                                                }}
                                                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200 text-black h-full"
                                                required
                                            />
                                        </div>
                                        <div className="flex flex-col w-full">
                                        <label className="block text-sm font-medium text-gray-700 mb-2">Unidad Medida</label>
                                            <select
                                                value={resource.idUnidadMedida}
                                                onChange={(e) => handleResourceUnitSelect(index, e.target.value)}
                                                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition duration-200 text-black"
                                                required
                                            >
                                                <option value="">Seleccione una unidad de medida</option>
                                                {units.map(unit => (
                                                    <option key={unit.id} value={unit.id}>
                                                        {unit.descripcion}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveResource(index)}
                                            className="text-red-500 hover:text-red-700"
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={handleAddResource}
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition duration-200"
                                >
                                    Agregar Recurso
                                </button>
                            </div>
                            <div className="flex justify-end space-x-4 pt-6 border-t border-gray-200">
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="px-6 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition duration-200"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-200"
                                >
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <CustomAlert
                open={alert.open}
                message={alert.message}
                severity={alert.severity}
                onClose={handleCloseAlert}
            />
        </div>
    );
};

export default Apu;
