import React, { useEffect, useState } from 'react';
import CustomAlert from '../components/CustomAlert';
import { api } from '../config/apiConfig';

const OT = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [estados, setEstados] = useState([]);
    const [cotizaciones, setCotizaciones] = useState([]);
    const [formData, setFormData] = useState({
        id: null,
        nombre: '',
        descripcion: '',
        idUsuario: localStorage.getItem('userId'),
        idCliente: '',
        idEstado: '',
        estado: 1,
        fechaInicio: '',
        fechaTermino: '',
        createdAt: '',
        updatedAt: ''
    });
    const [tareas, setTareas] = useState([]);
    const [tareaFormData, setTareaFormData] = useState({
        id: '',
        nombre: '',
        descripcion: '',
        fechaInicio: '',
        fechaFin: '',
        createdAt: '',
        updatedAt: ''
    });

    useEffect(() => {
        fetchData();
        fetchClientes();
        fetchEstados();
        fetchCotizaciones();
    }, []);



    const fetchData = async () => {
        try {
            const response = await api.get(`/ordenTrabajo`);
            setData(response.data);
        } catch (error) {
            setError('¡Hubo un error al obtener los datos!');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchClientes = async () => {
        try {
            const response = await api.get(`/cliente`);
            setClientes(response.data);
        } catch (error) {
            setError('¡Hubo un error al obtener los clientes!');
            console.error('Error:', error);
        }
    };

    const fetchEstados = async () => {
        try {
            const response = await api.get(`/estado`);
            setEstados(response.data);
        } catch (error) {
            setError('¡Hubo un error al obtener los estados!');
            console.error('Error:', error);
        }
    };

    const fetchCotizaciones = async () => {
        try {
            const response = await api.get(`/cotizacion`);
            setCotizaciones(response.data);
        } catch (error) {
            setError('¡Hubo un error al obtener las cotizaciones!');
            console.error('Error:', error);
        }
    };
   

    const handleAddTarea = async () => {
        try {
            const response = await api.post(`/tareaOT`, {
                ...tareaFormData,
                id: tareaFormData.id || null,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            });
            const nuevaTarea = response.data;
            setTareas([...tareas, nuevaTarea]);
            setTareaFormData({
                id: null,
                nombre: '',
                descripcion: '',
                fechaInicio: '',
                fechaFin: '',
                createdAt: '',
                updatedAt: ''
            });
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(`¡Error al crear la tarea! ${error.response.data.error}`);
            } else {
                setError('¡Error al crear la tarea!');
            }
            console.error('Error:', error.response?.data || error.message);
        }
    };

    const handleCreate = async () => {
        try {
            const response = await api.post(`/ordenTrabajo`, {
                ...formData,
                id: formData.id || null,
                idUsuario: localStorage.getItem('userId')
            });
            setData([...data, response.data]);
            const otId = response.data.id;

            for (const tarea of tareas) {
                await api.post(`/tareasOrdenUnion`, {
                    idTareaOT: tarea.id,
                    idOrdenTrabajo: otId,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                });
            }

            setFormData({
                id: null,
                nombre: '',
                descripcion: '',
                idUsuario: '',
                idCliente: '',
                idEstado: '',
                estado: 1,
                fechaInicio: '',
                fechaTermino: '',
                createdAt: '',
                updatedAt: ''
            });
            setTareas([]);
            console.log('Nueva OT creada:', response.data);
            setShowModal(false);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(`¡Error al crear la OT! ${error.response.data.error}`);
            } else {
                setError('¡Error al crear la OT!');
            }
            console.error('Error:', error.response?.data || error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleTareaInputChange = (e) => {
        const { name, value } = e.target;
        setTareaFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCotizacionChange = (e) => {
        const { value } = e.target;
        const cotizacionSeleccionada = cotizaciones.find(cotizacion => cotizacion.id === value);
        if (cotizacionSeleccionada) {
            setFormData(prevState => ({
                ...prevState,
                nombre: cotizacionSeleccionada.nombre,
                descripcion: cotizacionSeleccionada.descripcion,
                idUsuario: cotizacionSeleccionada.idUsuario,
                idCliente: cotizacionSeleccionada.idCliente
            }));
        }
    };

    const handleEdit = (id) => {
        const item = data.find(d => d.id === id);
        if (!item) {
            setError('¡Elemento no encontrado!');
            return;
        }
        if (Number(item.estado) === 0) {
            setError('¡No se puede editar OT inactivas!');
            return;
        }
        setFormData({
            id: item.id,
            nombre: item.nombre,
            descripcion: item.descripcion,
            idUsuario: item.idUsuario,
            idCliente: item.idCliente,
            idEstado: item.idEstado,
            fechaInicio: item.fechaInicio,
            fechaTermino: item.fechaTermino,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt
        });
        setShowModal(true);
        console.log('Editando elemento:', item);
    };

    const handleEstadoChange = async (id, nuevoEstado) => {
        try {
            await api.put(`/ordenTrabajo/${id}`, { estado: nuevoEstado });
            const updatedData = data.map(item => item.id === id ? { ...item, estado: nuevoEstado } : item);
            setData(updatedData);
            console.log('Estado actualizado:', id, nuevoEstado);
        } catch (error) {
            setError('¡Error al actualizar el estado!');
            console.error('Error:', error);
        }
    };

    const handleViewDetails = (id) => {
        const item = data.find(d => d.id === id);
        if (!item) {
            setError('¡Elemento no encontrado!');
            return;
        }
        console.log('Detalles del elemento:', item);
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8 text-black">
            <div className="flex justify-end mb-4">
                <button 
                    onClick={() => setShowModal(true)}
                    className="w-12 h-12 rounded-full bg-green-500 hover:bg-green-600 text-white transition-colors duration-300 flex items-center justify-center"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                    </svg>
                </button>
            </div>
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-6xl">
                        <h2 className="text-2xl font-bold mb-4">{formData.id ? 'Editar OT' : 'Nueva OT'}</h2>
                        <form onSubmit={(e) => { e.preventDefault(); handleCreate(); }}>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Cotización</label>
                                    <select name="cotizacion" onChange={handleCotizacionChange} className="mb-2 px-4 py-2 border rounded-lg w-full">
                                        <option value="">Seleccione una Cotización</option>
                                        {cotizaciones.map(cotizacion => (
                                            <option key={cotizacion.id} value={cotizacion.id}>{cotizacion.nombre}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-span-2">
                                    <label className="block text-sm font-medium text-gray-700">Descripción</label>
                                    <textarea name="descripcion" value={formData.descripcion} onChange={handleInputChange} placeholder="Descripción" className="mb-2 px-4 py-2 border rounded-lg w-full h-24" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Usuario</label>
                                    <input type="text" name="idUsuario" value={localStorage.getItem('nombre')} readOnly className="mb-2 px-4 py-2 border rounded-lg w-full" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Cliente</label>
                                    <select name="idCliente" value={formData.idCliente} onChange={handleInputChange} className="mb-2 px-4 py-2 border rounded-lg w-full">
                                        <option value="">Seleccione un Cliente</option>
                                        {clientes.map(cliente => (
                                            <option key={cliente.id} value={cliente.id}>{cliente.nombre}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Estado</label>
                                    <select name="idEstado" value={formData.idEstado} onChange={handleInputChange} className="mb-2 px-4 py-2 border rounded-lg w-full">
                                        <option value="">Seleccione un Estado</option>
                                        {estados.map(estado => (
                                            <option key={estado.id} value={estado.id}>{estado.nombre}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Fecha Inicio</label>
                                    <input type="date" name="fechaInicio" value={formData.fechaInicio} onChange={handleInputChange} className="mb-2 px-4 py-2 border rounded-lg w-full" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Fecha Término</label>
                                    <input type="date" name="fechaTermino" value={formData.fechaTermino} onChange={handleInputChange} className="mb-2 px-4 py-2 border rounded-lg w-full" />
                                </div>
                            </div>
                            <h3 className="text-xl font-bold mt-4 mb-2">Tareas</h3>
                            {tareas.map((tarea, index) => (
                                <div key={index} className="mb-2 p-4 border rounded-lg bg-gray-100">
                                    <p><strong>Nombre:</strong> {tarea.nombre}</p>
                                    <p><strong>Descripción:</strong> {tarea.descripcion}</p>
                                    <p><strong>Fecha Inicio:</strong> {tarea.fechaInicio}</p>
                                    <p><strong>Fecha Fin:</strong> {tarea.fechaFin}</p>
                                </div>
                            ))}
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Nombre de la Tarea</label>
                                    <input type="text" name="nombre" value={tareaFormData.nombre} onChange={handleTareaInputChange} placeholder="Nombre de la Tarea" className="mb-2 px-4 py-2 border rounded-lg w-full" />
                                </div>
                                <div className="col-span-2">
                                    <label className="block text-sm font-medium text-gray-700">Descripción de la Tarea</label>
                                    <textarea name="descripcion" value={tareaFormData.descripcion} onChange={handleTareaInputChange} placeholder="Descripción de la Tarea" className="mb-2 px-4 py-2 border rounded-lg w-full h-24" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Fecha Inicio</label>
                                    <input type="date" name="fechaInicio" value={tareaFormData.fechaInicio} onChange={handleTareaInputChange} className="mb-2 px-4 py-2 border rounded-lg w-full" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Fecha Fin</label>
                                    <input type="date" name="fechaFin" value={tareaFormData.fechaFin} onChange={handleTareaInputChange} className="mb-2 px-4 py-2 border rounded-lg w-full" />
                                </div>
                            </div>
                            <button 
                                type="button" 
                                onClick={handleAddTarea}
                                className="mb-4 px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors duration-300 flex items-center"
                            >
                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                                </svg>
                                Añadir Tarea
                            </button>
                            <div className="flex justify-end space-x-2">
                                <button 
                                    type="button" 
                                    onClick={() => setShowModal(false)}
                                    className="px-4 py-2 rounded-lg bg-gray-500 hover:bg-gray-600 text-white transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                    Cancelar
                                </button>
                                <button 
                                    type="submit" 
                                    className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                    {formData.id ? 'Actualizar' : 'Crear OT'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <div className="bg-white rounded-xl shadow-xl overflow-hidden">
                <div className="overflow-x-auto">
                    <table className="w-full table-auto">
                        <thead>
                            <tr className="bg-gradient-to-r from-blue-800 to-blue-900 text-white">
                                <th className="px-4 py-3 text-left font-semibold">Cliente</th>
                                <th className="px-4 py-3 text-left font-semibold">Nombre</th>
                                <th className="px-4 py-3 text-left font-semibold">Descripción</th>
                                <th className="px-4 py-3 text-left font-semibold">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-300">
                            {data.map((item) => (
                                <tr key={item.id} className="hover:bg-gray-100 transition-colors duration-300">
                                    <td className="px-6 py-4 text-gray-800">
                                        {clientes.find(cliente => cliente.id === item.idCliente)?.nombre || 'Cliente no encontrado'}
                                    </td>
                                    <td className="px-6 py-4 text-gray-800">{item.nombre}</td>
                                    <td className="px-6 py-4 text-gray-800">{item.descripcion}</td>
                                    <td className="px-6 py-4 flex justify-center space-x-3">
                                        <button 
                                            onClick={() => handleViewDetails(item.id)}
                                            className="flex justify-center items-center px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors duration-300"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0zm-3-9a9 9 0 00-9 9 9 9 0 0018 0 9 9 0 00-9-9z"/>
                                            </svg>
                                        </button>
                                        <button 
                                            onClick={() => handleEdit(item.id)}
                                            className={`flex justify-center items-center px-4 py-2 rounded-lg ${Number(item.estado) === 1 ? 'bg-cyan-500 hover:bg-cyan-600' : 'bg-gray-400 cursor-not-allowed'} text-white transition-colors duration-300`}
                                            disabled={Number(item.estado) !== 1}
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                            </svg>
                                        </button>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input 
                                                    type="checkbox" 
                                                    className="sr-only peer"
                                                    checked={Number(item.estado) === 1}
                                                    onChange={() => handleEstadoChange(item.id, Number(item.estado) === 1 ? 0 : 1)}
                                                />
                                                <div className={`w-14 h-7 rounded-full transition-colors duration-300 ease-in-out ${Number(item.estado) === 1 ? 'bg-green-500' : 'bg-gray-300'}`}>
                                                    <div className={`absolute w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${Number(item.estado) === 1 ? 'translate-x-8' : 'translate-x-1'} top-1`}></div>
                                                </div>
                                            </label>
                                        </label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <CustomAlert
                open={error !== null}
                message={error}
                severity="error"
                onClose={() => setError(null)}
            />
        </div>
    );
};

export default OT;
