// Guardar token y configurar expiración
export function saveToken(token, expirationTimeInSeconds) {
    localStorage.setItem("token", token);
    const expirationTime = Date.now() + expirationTimeInSeconds * 1000; // Tiempo en milisegundos
    localStorage.setItem("tokenExpiration", expirationTime);
    startTokenExpirationTimer(); // Iniciar el temporizador
}

// Verificar si el token ha expirado
export function isTokenExpired() {
    const expirationTime = localStorage.getItem("tokenExpiration");
    if (!expirationTime) return true;
    return Date.now() > parseInt(expirationTime, 10);
}

// Eliminar token automáticamente al expirar
export function startTokenExpirationTimer() {
    const expirationTime = localStorage.getItem("tokenExpiration");
    if (!expirationTime) return;

    const timeLeft = parseInt(expirationTime, 10) - Date.now();
    if (timeLeft > 0) {
        setTimeout(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            console.log("El token ha expirado y ha sido eliminado.");
            window.location.href = "/login"; // Redirigir al login
        }, timeLeft);
    } else {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        console.log("El token ya ha expirado.");
        window.location.href = "/login"; // Redirigir al login
    }
}

// Llamar esta función automáticamente al cargar la aplicación
startTokenExpirationTimer();
