import React, { useState, useEffect } from 'react';
import CustomAlert from '../components/CustomAlert';
import Modal from '../components/Modal';
import { api } from '../config/apiConfig';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Cotizacion = () => {
    const inicializarFormData = () => ({
        nombre: '',
        descripcion: '',
        idCliente: '',
        idUsuario: localStorage.getItem('userId'),
        fechaActual: new Date().toISOString().split('T')[0],
        fechaVencimiento: new Date(new Date().setDate(new Date().getDate() + 10)).toISOString().split('T')[0],
        idEstado: 2,
        diasCotizacion: 10,
        valorSinIva: 0,
        valorConIva: 0,
        productos: [],
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
    });

    const inicializarProducto = () => ({
        id: '',
        nombre: '',
        recursos: [],
        cantidad: 1,
        precioUnitario: 0,
        subtotal: 0
    });

    const inicializarAlert = () => ({
        open: false,
        message: '',
        severity: 'info'
    });

    const [formData, setFormData] = useState(inicializarFormData());
    const [producto, setProducto] = useState(inicializarProducto());
    const [editMode, setEditMode] = useState(false);
    const [editingCotizacion, setEditingCotizacion] = useState(null);
    const [estados, setEstados] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [apus, setApus] = useState([]);
    const [recursos, setRecursos] = useState([]); // Cambiado de [setRecursos] a recursos, setRecursos
    const [cotizaciones, setCotizaciones] = useState([]);
    const [clienteSeleccionado, setClienteSeleccionado] = useState(null); // Cambiado de [setClienteSeleccionado] a clienteSeleccionado, setClienteSeleccionado
    const [showModal, setShowModal] = useState(false);
    const [alert, setAlert] = useState(inicializarAlert());
    const [tipoRecursos, setTipoRecursos] = useState([]);
    const [unidadMedida, setUnidadMedida] = useState([]);
    const [showDetalleModal, setShowDetalleModal] = useState(false);
    const [cotizacionDetalle, setCotizacionDetalle] = useState(null);


    const userRole = localStorage.getItem('role');

    useEffect(() => {
        cargarDatosIniciales();
    }, []); // Agregado [] para que se ejecute solo una vez
    
    const cargarDatosIniciales = () => {
        Promise.all([
            api.get(`/estado`).catch(e => manejarError(e, 'estado')),
            api.get(`/cliente`).catch(e => manejarError(e, 'cliente')),
            api.get(`/apu`).catch(e => manejarError(e, 'apu')),
            api.get(`/recurso`).catch(e => manejarError(e, 'recurso')),
            api.get(`/cotizacion`).catch(e => manejarError(e, 'cotizacion')),
            api.get(`/tipoRecurso`).catch(e => manejarError(e, 'tipoRecurso')),
            api.get(`/unidadMedida`).catch(e => manejarError(e, 'unidadMedida'))
        ])
        .then(responses => {
            setEstados(responses[0].data);
            setClientes(responses[1].data);
            setApus(responses[2].data);
            setRecursos(responses[3].data);
            setCotizaciones(responses[4].data);
            setTipoRecursos(responses[5].data);
            setUnidadMedida(responses[6].data);
        })
        .catch(error => {
            console.error('Error general:', error);
            showAlert('Error al cargar los datos. Por favor, revise la conexión con el servidor.', 'error');
        });
    };

    const manejarError = (error, tipo) => {
        console.error(`Error en ${tipo}:`, error);
        return { data: [] };
    };

    const calcularDiasCotizacion = (fechaVencimiento) => {
        const fechaActual = new Date(formData.fechaActual);
        const fechaVenc = new Date(fechaVencimiento);
        const diferencia = fechaVenc.getTime() - fechaActual.getTime();
        return Math.ceil(diferencia / (1000 * 3600 * 24));
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        
        if (name === 'fechaVencimiento') {
            const diasCotizacion = calcularDiasCotizacion(value);
            setFormData(prev => ({
                ...prev,
                [name]: value,
                diasCotizacion
            }));
        } else if (name === 'diasCotizacion') {
            const nuevaFechaVencimiento = new Date(formData.fechaActual);
            nuevaFechaVencimiento.setDate(nuevaFechaVencimiento.getDate() + parseInt(value));
            setFormData(prev => ({
                ...prev,
                diasCotizacion: parseInt(value),
                fechaVencimiento: nuevaFechaVencimiento.toISOString().split('T')[0]
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }

        if (name === 'idCliente') {
            try {
                const response = await api.get(`/cliente/${value}`);
                setClienteSeleccionado(response.data);
            } catch (error) {
                console.error('Error al obtener detalles del cliente:', error);
                setClienteSeleccionado(null);
            }
        }
    };

    const handleProductoChange = async (e) => {
        const { name, value } = e.target;
        
        if (name === 'id') {
            const apuSeleccionada = apus.find(apu => apu.id === parseInt(value));
            if (apuSeleccionada) {
                try {
                    const apuRecursosResponse = await api.get(`/apuRecurso`);
                    const apuRecursos = apuRecursosResponse.data.filter(ar => ar.id_apu === parseInt(value));
                    
                    let precioUnitario = 0;
                    const recursosDetallados = await Promise.all(
                        apuRecursos.map(async (apuRecurso) => {
                            const recursoResponse = await api.get(`/recurso/${apuRecurso.id_recurso}`);
                            const recurso = recursoResponse.data;
                            precioUnitario += apuRecurso.precio * apuRecurso.cantidad;
                            return {
                                ...recurso,
                                cantidad: apuRecurso.cantidad,
                                tipoRecurso: tipoRecursos.find(tr => tr.id === recurso.idTipoRecurso),
                                unidadMedida: unidadMedida.find(um => um.id === recurso.idUnidadMedida),
                                precioPeso: apuRecurso.precio 
                            };
                        })
                    );

                    setProducto({
                        id: value,
                        nombre: apuSeleccionada.nombre,
                        descripcion: apuSeleccionada.descripcion,
                        recursos: recursosDetallados,
                        cantidad: 1,
                        precioUnitario: precioUnitario,
                        subtotal: precioUnitario
                    });
                } catch (error) {
                    showAlert('Error al cargar los recursos de la APU', 'error');
                }
            }
        } else if (name === 'cantidad') {
            const cantidad = parseInt(value) || 0;
            setProducto(prev => ({
                ...prev,
                cantidad: cantidad,
                subtotal: cantidad * prev.precioUnitario
            }));
        }
    };

    const agregarProducto = () => {
        if (!producto.id) {
            showAlert('Por favor seleccione una APU', 'warning');
            return;
        }

        const subtotalRecursos = producto.recursos.reduce((total, recurso) => {
            return total + ((recurso.cantidad || 0) * (recurso.precioPeso || 0));
        }, 0);

        const nuevaApu = {
            id: producto.id,
            nombre: producto.nombre,
            descripcion: producto.descripcion,
            recursos: producto.recursos,
            cantidad: producto.cantidad || 1,
            precioUnitario: subtotalRecursos,
            subtotal: subtotalRecursos * (producto.cantidad || 1)
        };

        setFormData(prev => ({
            ...prev,
            productos: [...prev.productos, nuevaApu],
            total: prev.total + nuevaApu.subtotal
        }));

        setProducto({
            id: '',
            nombre: '',
            recursos: [],
            cantidad: 1,
            precioUnitario: 0,
            subtotal: 0
        });
        showAlert('APU agregada exitosamente', 'success');
    };

    const eliminarProducto = (index) => {
        setFormData(prev => {
            const nuevosProductos = [...prev.productos];
            const productoEliminado = nuevosProductos.splice(index, 1)[0];
            showAlert('Producto eliminado exitosamente', 'success');
            return {
                ...prev,
                productos: nuevosProductos,
                total: prev.total - productoEliminado.subtotal
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.nombre || !formData.idCliente) {
            showAlert('Por favor complete todos los campos requeridos', 'error');
            return;
        }

        try {
            const productos = Array.isArray(formData.productos) ? formData.productos : [];

            const valorSinIva = productos.reduce((sum, prod) => sum + (prod.subtotal || 0), 0);
            const valorConIva = valorSinIva * 1.19;

            const cotizacionData = {
                nombre: formData.nombre.trim(),
                descripcion: formData.descripcion?.trim() || '',
                idCliente: parseInt(formData.idCliente),
                idUsuario: parseInt(formData.idUsuario),
                fechaActual: new Date(formData.fechaActual).toISOString(),
                FechaVencimiento: new Date(formData.fechaVencimiento).toISOString(),
                idEstado: formData.idEstado,
                diasCotizacion: parseInt(formData.diasCotizacion) || 10,
                valorSinIva: parseFloat(valorSinIva) || 0,
                valorConIva: parseFloat(valorConIva) || 0,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString()
            };

            let response;
            if (editMode && editingCotizacion) {
                response = await api.put(`/cotizacion/${editingCotizacion.id}`, cotizacionData);
                await api.delete(`/recursoApuCotizacion/${editingCotizacion.id}`);
            } else {
                response = await api.post(`/cotizacion`, cotizacionData);
            }

            const cotizacionId = editMode ? editingCotizacion.id : response.data.id;

            await Promise.all(productos.map(async (producto) => {
                await api.post(`/recursoApuCotizacion`, {
                    id_cotizacion: cotizacionId,
                    id_apu: parseInt(producto.id),
                    id_recurso: producto.recursos[0].id,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                });

                await Promise.all(producto.recursos
                    .filter(recurso => recurso.cantidad >= 1 && recurso.id)
                    .map(recurso => {
                        const recursoApuCotizacionData = {
                            id_cotizacion: cotizacionId,
                            id_apu: parseInt(producto.id),
                            id_recurso: recurso.id,
                            cantidad: recurso.cantidad,
                            precio_unitario: recurso.precioPeso,
                            subtotal: recurso.cantidad * recurso.precioPeso,
                            createdAt: new Date().toISOString(),
                            updatedAt: new Date().toISOString()
                        };
                        return api.post(`/recursoApuCotizacion`, recursoApuCotizacionData);
                    })
                );
            }));

            showAlert(`Cotización ${editMode ? 'actualizada' : 'creada'} exitosamente`, 'success');
            setShowModal(false);
            setEditMode(false);
            setEditingCotizacion(null);
            
            const cotizacionesRes = await api.get(`/cotizacion`);
            setCotizaciones(cotizacionesRes.data);
            
            limpiarFormulario();
        } catch (error) {
            console.error('Error detallado:', error.response?.data);
            showAlert('Error al actualizar la cotización', 'error');
            console.error(`Error al ${editMode ? 'actualizar' : 'crear'} la cotización:`, error);
        }
    };
    

    const limpiarFormulario = () => {
        const fechaActual = new Date();
        const fechaVencimiento = new Date(fechaActual);
        fechaVencimiento.setDate(fechaVencimiento.getDate() + 10);

        setFormData({
            nombre: '',
            descripcion: '',
            idCliente: '',
            idUsuario: localStorage.getItem('userId'),
            fechaActual: fechaActual.toISOString().split('T')[0],
            fechaVencimiento: fechaVencimiento.toISOString().split('T')[0],
            idEstado: 2,
            diasCotizacion: 10,
            valorSinIva: 0,
            valorConIva: 0,
            productos: [],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            urlImagen: ''
        });
        setClienteSeleccionado(null);
        setEditMode(false);
        setEditingCotizacion(null);
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const showAlert = (message, severity) => {
        setAlert({
            open: true,
            message,
            severity
        });
    };

    const verDetalles = async (cotizacionId) => {
        try {
            const response = await api.get(`/cotizacion/${cotizacionId}`);
            const cotizacion = response.data;

            // Obtener las APUs y recursos asociados a la cotización desde la ruta /recursoApuCotizacion
            const recursoApuCotizacionResponse = await api.get(`/recursoApuCotizacion?cotizacionId=${cotizacionId}`);
            const recursoApuCotizaciones = recursoApuCotizacionResponse.data.filter(item => item.id_cotizacion === cotizacionId);

            // Obtener los nombres de los recursos
            const recursosMap = {};
            await Promise.all(recursoApuCotizaciones.map(async (item) => {
                if (!recursosMap[item.id_recurso]) {
                    const recursoResponse = await api.get(`/recurso/${item.id_recurso}`);
                    recursosMap[item.id_recurso] = recursoResponse.data.nombre;
                }
            }));
            // Agrupar los apu por id y obtener el nombre 
            const apuMap = {};
            recursoApuCotizaciones.forEach(item => {
                if (!apuMap[item.id_apu]) {
                    apuMap[item.id_apu] = [];
                }
                apuMap[item.id_apu].push(item);
            });

            // Agrupar los recursos por APU
            const apuDetalles = [];
            for (const item of recursoApuCotizaciones) {
                const apuIndex = apuDetalles.findIndex(apu => apu.id_apu === item.id_apu);
                const apuNombre = await api.get(`/apu/${item.id_apu}`).then(res => res.data.nombre);
                if (apuIndex === -1) {
                    apuDetalles.push({
                        id_apu: item.id_apu,
                        nombre: apuNombre,
                        recursos: [{
                            nombre: recursosMap[item.id_recurso],
                            cantidad: item.cantidad,
                            precio_unitario: item.precio_unitario,
                            subtotal: item.subtotal
                        }]
                    });
                } else { 
                    apuDetalles[apuIndex].recursos.push({
                        nombre: recursosMap[item.id_recurso],
                        cantidad: item.cantidad,
                        precio_unitario: item.precio_unitario,
                        subtotal: item.subtotal
                    });
                }
            }

            const clienteResponse = await api.get(`/cliente/${cotizacion.idCliente}`);
            const cliente = clienteResponse.data;

            setCotizacionDetalle({
                ...cotizacion,
                apuDetalles: apuDetalles,
                cliente: cliente
            });
            setShowDetalleModal(true);
        } catch (error) {
            console.error('Error al obtener detalles de la cotización:', error);
            showAlert('Error al cargar los detalles de la cotización', 'error');
        }
    };

    const editCotizacion = async (cotizacion) => {
        try {
            const response = await api.get(`/cotizacion/${cotizacion.id}`);
            const cotizacionData = response.data;
            console.log('Detalles de la cotización para editar:', cotizacionData);
    
            // Obtener las APUs y recursos asociados a la cotización desde la ruta /recursoApuCotizacion
            const recursoApuCotizacionResponse = await api.get(`/recursoApuCotizacion?cotizacionId=${cotizacion.id}`);
            const recursoApuCotizaciones = recursoApuCotizacionResponse.data.filter(item => item.id_cotizacion === cotizacion.id);
    
            // Verificar y obtener los nombres de los recursos
            const recursosMap = {};
            await Promise.all(recursoApuCotizaciones.map(async (item) => {
                if (item.id_recurso && !recursosMap[item.id_recurso]) {
                    const recursoResponse = await api.get(`/recurso/${item.id_recurso}`);
                    recursosMap[item.id_recurso] = recursoResponse.data.nombre;
                }
            }));
    
            // Agrupar los apu por id y obtener el nombre 
            const apuMap = {};
            recursoApuCotizaciones.forEach(item => {
                if (!apuMap[item.id_apu]) {
                    apuMap[item.id_apu] = [];
                }
                apuMap[item.id_apu].push(item);
            });
    
            // Agrupar los recursos por APU
            const apuDetalles = [];
            for (const item of recursoApuCotizaciones) {
                const apuIndex = apuDetalles.findIndex(apu => apu.id_apu === item.id_apu);
                const apuNombre = await api.get(`/apu/${item.id_apu}`).then(res => res.data.nombre);
                if (apuIndex === -1) {
                    apuDetalles.push({
                        id_apu: item.id_apu,
                        nombre: apuNombre,
                        recursos: [{
                            nombre: recursosMap[item.id_recurso] || 'Recurso no encontrado',
                            cantidad: item.cantidad,
                            precio_unitario: item.precio_unitario,
                            subtotal: item.subtotal
                        }]
                    });
                } else { 
                    apuDetalles[apuIndex].recursos.push({
                        nombre: recursosMap[item.id_recurso] || 'Recurso no encontrado',
                        cantidad: item.cantidad,
                        precio_unitario: item.precio_unitario,
                        subtotal: item.subtotal
                    });
                }
            }
    
            setEditingCotizacion({
                ...cotizacionData,
                productos: apuDetalles.map(apu => ({
                    id: apu.id_apu,
                    nombre: apu.nombre,
                    descripcion: '',
                    recursos: apu.recursos,
                    cantidad: 1,
                    precioUnitario: apu.recursos.reduce((total, recurso) => total + recurso.precio_unitario * recurso.cantidad, 0),
                    subtotal: apu.recursos.reduce((total, recurso) => total + recurso.subtotal, 0)
                }))
            });
    
            setFormData({
                nombre: cotizacionData.nombre,
                descripcion: cotizacionData.descripcion,
                idCliente: cotizacionData.idCliente,
                idUsuario: cotizacionData.idUsuario,
                fechaActual: new Date(cotizacionData.fechaActual).toISOString().split('T')[0],
                fechaVencimiento: new Date(cotizacionData.FechaVencimiento).toISOString().split('T')[0],
                idEstado: cotizacionData.idEstado,
                diasCotizacion: cotizacionData.diasCotizacion,
                valorSinIva: cotizacionData.valorSinIva,
                valorConIva: cotizacionData.valorConIva,
                productos: apuDetalles.map(apu => ({
                    id: apu.id_apu,
                    nombre: apu.nombre,
                    descripcion: '',
                    recursos: apu.recursos,
                    cantidad: 1,
                    precioUnitario: apu.recursos.reduce((total, recurso) => total + recurso.precio_unitario * recurso.cantidad, 0),
                    subtotal: apu.recursos.reduce((total, recurso) => total + recurso.subtotal, 0)
                })),
                createdAt: cotizacionData.createdAt,
                updatedAt: cotizacionData.updatedAt
            });
    
            setShowModal(true);
            setEditMode(true);
        } catch (error) {
            console.error('Error al obtener detalles de la cotización para editar:', error);
            showAlert('Error al cargar los detalles de la cotización para editar', 'error');
        }
    };

    const handleEstadoChange = async (cotizacionId, nuevoEstadoId) => {
        try {
            await api.put(`/cotizacion/${cotizacionId}`, { idEstado: nuevoEstadoId });
            showAlert('Estado actualizado exitosamente', 'success');
            
            const cotizacionesRes = await api.get(`/cotizacion`);
            setCotizaciones(cotizacionesRes.data);
        } catch (error) {
            console.error('Error al actualizar el estado:', error);
            showAlert('Error al actualizar el estado', 'error');
        }
    };

    const handleCantidadChange = (index, newCantidad) => {
        setProducto(prev => {
            const nuevosRecursos = [...prev.recursos];
            nuevosRecursos[index].cantidad = parseInt(newCantidad) || 0;
            const nuevoSubtotal = nuevosRecursos.reduce((total, recurso) => {
                return total + (recurso.cantidad * recurso.precioPeso);
            }, 0);
            return {
                ...prev,
                recursos: nuevosRecursos,
                subtotal: nuevoSubtotal
            };
        });
    };

    const generarPDF = () => {
        const doc = new jsPDF();

        // Estilo de título
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        doc.text('Cotización Detallada', 105, 20, { align: 'center' });

        // Estilo de subtítulos
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`Nombre: ${cotizacionDetalle.nombre}`, 20, 40);
        doc.text(`Fecha Emisión: ${new Date(cotizacionDetalle.fechaActual).toLocaleDateString()}`, 20, 50);
        doc.text(`Fecha Vencimiento: ${new Date(cotizacionDetalle.FechaVencimiento).toLocaleDateString()}`, 20, 60);
        doc.text(`Cliente: ${cotizacionDetalle.cliente?.nombre} ${cotizacionDetalle.cliente?.apellido}`, 20, 70);
        doc.text(`Empresa: ${cotizacionDetalle.cliente?.nombreEmpresa}`, 20, 80);
        doc.text(`RUT: ${cotizacionDetalle.cliente?.rut}`, 20, 90);

        // Estilo de resumen
        doc.setFont('helvetica', 'bold');
        doc.text(`Valor Sin IVA: $${cotizacionDetalle.valorSinIva.toLocaleString('es-CL')}`, 20, 110);
        doc.text(`Valor Con IVA: $${cotizacionDetalle.valorConIva.toLocaleString('es-CL')}`, 20, 120);

        // Generar nombre de archivo con fecha y hora
        const fechaHoraActual = new Date();
        const nombreArchivo = `cotizacion_${fechaHoraActual.toISOString().replace(/[:.]/g, '-')}.pdf`;

        doc.save(nombreArchivo);
    };

    const enviarCorreo = async () => {
        try {
            // Verificar si el correo del cliente está disponible
            const emailCliente = cotizacionDetalle.cliente?.correo;
            if (!emailCliente) {
                return showAlert('Correo del cliente no encontrado', 'error');
            }
    
            // Generar el PDF
            const doc = new jsPDF();
            doc.setFontSize(16).setFont('helvetica', 'bold');
            doc.text('Cotización Detallada', 105, 20, { align: 'center' });
    
            doc.setFontSize(12).setFont('helvetica', 'normal');
            const detalles = [
                `Nombre: ${cotizacionDetalle.nombre}`,
                `Fecha Emisión: ${new Date(cotizacionDetalle.fechaActual).toLocaleDateString()}`,
                `Fecha Vencimiento: ${new Date(cotizacionDetalle.FechaVencimiento).toLocaleDateString()}`,
                `Cliente: ${cotizacionDetalle.cliente?.nombre} ${cotizacionDetalle.cliente?.apellido}`,
                `Empresa: ${cotizacionDetalle.cliente?.nombreEmpresa}`,
                `RUT: ${cotizacionDetalle.cliente?.rut}`,
            ];
            detalles.forEach((detalle, index) => doc.text(detalle, 20, 40 + (index * 10)));
    
            doc.setFont('helvetica', 'bold');
            doc.text(`Valor Sin IVA: $${cotizacionDetalle.valorSinIva.toLocaleString('es-CL')}`, 20, 110);
            doc.text(`Valor Con IVA: $${cotizacionDetalle.valorConIva.toLocaleString('es-CL')}`, 20, 120);
    
            // Generar el PDF como blob
            const pdfBlob = doc.output('blob');
    
            // Crear FormData para enviar el correo
            const formData = new FormData();
            formData.append('to', emailCliente.toString()); // Correo del cliente
            formData.append('subject', 'Cotización Detallada');
            formData.append('message', 'Adjunto encontrará la cotización detallada.');
            formData.append('attachment', new Blob([pdfBlob], { type: 'application/pdf' }), 'cotizacion.pdf');
    
            // Configuración del encabezado
            const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    
            for (let pair of formData.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
            
            // Enviar la solicitud al backend
            try {
                await api.post(`/send-alert`, formData, config);
                showAlert('Correo enviado exitosamente', 'success');
            } catch (error) {
                console.error('Error al enviar el correo:', error);
                showAlert('Hubo un error al intentar enviar el correo', 'error');
            }
        } catch (error) {
            console.error('Error al generar el PDF:', error);
            showAlert('Hubo un error al generar el PDF', 'error');
        }
    };
    
    
    

    return (
        <div className="container mx-auto p-4">
            <CustomAlert
                open={alert.open}
                message={alert.message}
                severity={alert.severity}
                onClose={handleCloseAlert}
            />
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Cotizaciones</h1>
                <button 
                    onClick={() => {
                        setShowModal(true);
                        setEditMode(false);
                        setEditingCotizacion(null);
                        limpiarFormulario();
                    }}
                    className="w-12 h-12 rounded-full bg-green-500 hover:bg-green-600 text-white flex items-center justify-center shadow-lg"
                >
                    <span className="text-2xl">+</span>
                </button>
            </div>

            <div className="overflow-x-auto shadow-lg rounded-lg mb-6">
                <table className="min-w-full bg-white">
                    <thead className="bg-blue-800 text-white">
                        <tr>
                            <th className="px-4 py-3">Nombre Cotización</th>
                            <th className="px-4 py-3">Cliente</th>
                            <th className="px-4 py-3">Descripción</th>
                            <th className="px-4 py-3">Fecha Emisión</th>
                            <th className="px-4 py-3">Fecha Vencimiento</th>
                            <th className="px-4 py-3">Estado</th>
                            <th className="px-4 py-3">Total</th>
                            <th className="px-4 py-3">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {cotizaciones && cotizaciones.map((cotizacion) => {
                            const cliente = clientes ? clientes.find(c => c.id === cotizacion.idCliente) : null;
                            const estado = estados ? estados.find(e => e.id === cotizacion.idEstado) : null;
                            
                            return (
                                <tr key={cotizacion.id} className="hover:bg-gray-100 transition-colors duration-200">
                                    <td className="border-t px-4 py-3">
                                        {cotizacion.nombre}
                                    </td>
                                    <td className="border-t px-4 py-3">
                                        {cliente ? `${cliente.nombre} ${cliente.apellido}` : 'Cliente no encontrado'}
                                    </td>
                                    <td className="border-t px-4 py-3">
                                        {cotizacion.descripcion}
                                    </td>
                                    <td className="border-t px-4 py-3">
                                        {new Date(cotizacion.fechaActual).toLocaleDateString()}
                                    </td>
                                    <td className="border-t px-4 py-3">
                                        {new Date(cotizacion.FechaVencimiento).toLocaleDateString()}
                                    </td>
                                    <td className="border-t px-4 py-3">
                                        <span className={`px-2 py-1 rounded-full text-sm ${
                                            estado?.nombre === 'En Espera' ? 'bg-yellow-200 text-yellow-800' :
                                            estado?.nombre === 'Aceptado' ? 'bg-green-200 text-green-800' :
                                            estado?.nombre === 'Rechazado' ? 'bg-red-200 text-red-800' :
                                            'bg-gray-200 text-gray-800'
                                        }`}>
                                            {estado?.nombre || 'Desconocido'}
                                        </span>
                                    </td>
                                    <td className="border-t px-4 py-3">
                                        ${(cotizacion.valorConIva || 0).toLocaleString('es-CL')}
                                    </td>
                                    <td className="border-t px-4 py-3 flex space-x-2">
                                        <button
                                            onClick={() => verDetalles(cotizacion.id)}
                                            className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1 rounded"
                                        >
                                            Ver Detalles
                                        </button>
                                        {userRole === 'Administrador' && (
                                            <>
                                                <button
                                                    onClick={() => editCotizacion(cotizacion)}
                                                    className="bg-cyan-500 hover:bg-cyan-600 text-white px-3 py-1 rounded"
                                                >
                                                    Editar
                                                </button>
                                                <select
                                                    value={cotizacion.idEstado}
                                                    onChange={(e) => handleEstadoChange(cotizacion.id, e.target.value)}
                                                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
                                                >
                                                    {estados && estados.map(estado => (
                                                        <option key={estado.id} value={estado.id}>
                                                            {estado.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                title={editingCotizacion ? "Editar Cotización" : "Nueva Cotización"}
            >
                <form onSubmit={handleSubmit} className="bg-white text-black p-6">
                    {/* Sección 1: Información básica */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Nombre Cotización</label>
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleChange}
                                className="w-full border rounded p-2"
                                required
                                placeholder="Ingrese nombre de la cotización"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Cliente</label>
                            <select 
                                name="idCliente"
                                value={formData.idCliente}
                                onChange={handleChange}
                                className="w-full border rounded p-2"
                                required
                            >
                                <option value="">Seleccione un cliente</option>
                                {clientes.map(cliente => (
                                    <option key={cliente.id} value={cliente.id}>
                                        {cliente.nombre} {cliente.apellido}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Fecha Actual</label>
                            <input
                                type="date"
                                name="fechaActual"
                                value={formData.fechaActual}
                                onChange={handleChange}
                                className="w-full border rounded p-2"
                                required
                                readOnly
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Fecha Vencimiento</label>
                            <input
                                type="date"
                                name="fechaVencimiento"
                                value={formData.fechaVencimiento}
                                onChange={handleChange}
                                className="w-full border rounded p-2"
                                required
                            />
                            <p className="text-sm text-gray-600 mt-1">
                                Días de cotización: {formData.diasCotizacion}
                            </p>
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Descripción</label>
                            <input
                                type="text"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleChange}
                                className="w-full border rounded p-2"
                                placeholder="Descripción de la cotización"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Estado</label>
                            <select 
                                name="idEstado"
                                value={formData.idEstado}
                                onChange={handleChange}
                                className="w-full border rounded p-2"
                                required
                            >
                                {estados && estados.map(estado => (
                                    <option key={estado.id} value={estado.id}>
                                        {estado.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>

                    {/* Sección 2: Selección y visualización de APUs */}
                    <div className="mt-6 border-t pt-4">
                        <h3 className="text-lg font-semibold mb-4">Partidas a realizar</h3>
                        
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div>
                                <label className="block text-sm font-medium mb-1">Seleccionar APU</label>
                                <select
                                    name="id"
                                    value={producto.id}
                                    onChange={handleProductoChange}
                                    className="w-full border rounded p-2"
                                >
                                    <option value="">Seleccione una APU</option>
                                    {apus.map(apu => (
                                        <option key={apu.id} value={apu.id}>
                                            {apu.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {/* Tabla de recursos de la APU seleccionada */}
                        {producto.recursos.length > 0 && (
                            <div className="mb-4">
                                <h4 className="text-md font-medium mb-2">Recursos de la APU</h4>
                                <table className="min-w-full bg-white border border-gray-200">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th className="px-4 py-2 text-left text-gray-900 border-b">Recurso</th>
                                            <th className="px-4 py-2 text-left text-gray-900 border-b">Precio Unitario</th>
                                            <th className="px-4 py-2 text-left text-gray-900 border-b">Cantidad</th>
                                            <th className="px-4 py-2 text-left text-gray-900 border-b">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {producto.recursos.map((recurso, index) => (
                                            <tr key={index} className="hover:bg-gray-50">
                                                <td className="px-4 py-2 border-b">{recurso.nombre}</td>
                                                <td className="px-4 py-2 border-b">${recurso.precioPeso.toLocaleString('es-CL')}</td>
                                                <td className="px-4 py-2 border-b">
                                                    <input
                                                        type="number"
                                                        value={recurso.cantidad}
                                                        onChange={(e) => handleCantidadChange(index, e.target.value)}
                                                        className="w-full border rounded p-1"
                                                        min="1"
                                                    />
                                                </td>
                                                <td className="px-4 py-2 border-b">
                                                    ${(recurso.cantidad * recurso.precioPeso).toLocaleString('es-CL')}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        <button
                            type="button"
                            onClick={agregarProducto}
                            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                        >
                            Agregar APU
                        </button>
                    </div>

                    {/* Sección 3: APUs agregadas */}
                    {formData.productos.length > 0 && (
                        <div className="mt-6 border-t pt-4">
                            <h3 className="text-lg font-semibold mb-4">APUs Agregadas</h3>
                            <div className="space-y-4">
                                {formData.productos.map((prod, index) => (
                                    <div key={index} className="border p-4 rounded">
                                        <div className="flex justify-between items-center mb-2">
                                            <h4 className="font-medium">{prod.nombre}</h4>
                                            <button
                                                type="button"
                                                onClick={() => eliminarProducto(index)}
                                                className="text-red-500 hover:text-red-700"
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                        <div className="mt-2">
                                            <h5 className="font-semibold">Recursos:</h5>
                                            <ul className="list-disc pl-5">
                                                {prod.recursos
                                                    .filter(recurso => recurso.cantidad >= 1) // Filtrar recursos con cantidad >= 1
                                                    .map((recurso, rIndex) => (
                                                        <li key={rIndex} className="text-gray-700">
                                                            {recurso.nombre} - Cantidad: {recurso.cantidad}, Subtotal: ${(recurso.cantidad * recurso.precioPeso).toLocaleString('es-CL')}
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                        <p className="mt-2">Subtotal APU: ${prod.subtotal.toLocaleString('es-CL')}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Sección 4: Totales */}
                    <div className="mt-6 border-t pt-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block text-sm font-medium mb-1">Valor Sin IVA</label>
                                <input
                                    type="number"
                                    name="valorSinIva"
                                    value={formData.productos.reduce((sum, prod) => sum + (prod.subtotal || 0), 0)}
                                    className="w-full border rounded p-2"
                                    readOnly
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-1">Valor Con IVA</label>
                                <input
                                    type="number"
                                    name="valorConIva"
                                    value={formData.productos.reduce((sum, prod) => sum + (prod.subtotal || 0), 0) * 1.19}
                                    className="w-full border rounded p-2"
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>

                    {/* Botón de submit */}
                    <div className="flex justify-end mt-6">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                        >
                            {editingCotizacion ? "Actualizar" : "Crear"}
                        </button>
                    </div>
                </form>
            </Modal>

            <Modal
                isOpen={showDetalleModal}
                onClose={() => setShowDetalleModal(false)}
                title="Detalles de la Cotización"
            >
                {cotizacionDetalle && (
                    <div className="p-6 text-gray-900 bg-white rounded-lg shadow-md">
                        <div className="mb-6 border-b pb-4">
                            <h3 className="text-3xl font-bold mb-4 text-center text-gray-900">Cotización Detallada</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-2">
                                    <p className="text-gray-900"><strong>Nombre:</strong> {cotizacionDetalle.nombre}</p>
                                    <p className="text-gray-900"><strong>Fecha Emisión:</strong> {new Date(cotizacionDetalle.fechaActual).toLocaleDateString()}</p>
                                    <p className="text-gray-900"><strong>Fecha Vencimiento:</strong> {new Date(cotizacionDetalle.FechaVencimiento).toLocaleDateString()}</p>
                                </div>
                                <div className="space-y-2">
                                    <p className="text-gray-900"><strong>Cliente:</strong> {cotizacionDetalle.cliente?.nombre} {cotizacionDetalle.cliente?.apellido}</p>
                                    <p className="text-gray-900"><strong>Empresa:</strong> {cotizacionDetalle.cliente?.nombreEmpresa}</p>
                                    <p className="text-gray-900"><strong>RUT:</strong> {cotizacionDetalle.cliente?.rut}</p>
                                    <p className="text-gray-900"><strong>Correo:</strong> {cotizacionDetalle.cliente?.correo}</p>
                                </div>
                            </div>
                        </div>

                        <div className="mb-6 border-b pb-4">
                            <h3 className="text-xl font-bold mb-4 text-gray-900">APUs Incluidas</h3>
                            <ul className="list-disc pl-5">
                                {cotizacionDetalle.apuDetalles && cotizacionDetalle.apuDetalles.map((apu, index) => (
                                    <li key={index} className="text-gray-900 mb-4">
                                        <strong className="block text-lg">{apu.nombre}</strong>
                                        <table className="min-w-full bg-white border border-gray-200 mt-2">
                                            <thead className="bg-gray-100">
                                                <tr>
                                                    <th className="px-4 py-2 text-left text-gray-900 border-b">Recurso</th>
                                                    <th className="px-4 py-2 text-left text-gray-900 border-b">Cantidad</th>
                                                    <th className="px-4 py-2 text-left text-gray-900 border-b">Precio Unitario</th>
                                                    <th className="px-4 py-2 text-left text-gray-900 border-b">Subtotal</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {apu.recursos.map((recurso, rIndex) => (
                                                    <tr key={rIndex} className="hover:bg-gray-50">
                                                        <td className="px-4 py-2 text-gray-700 border-b">{recurso.nombre}</td>
                                                        <td className="px-4 py-2 text-gray-700 border-b">{recurso.cantidad}</td>
                                                        <td className="px-4 py-2 text-gray-700 border-b">${recurso.precio_unitario.toLocaleString('es-CL')}</td>
                                                        <td className="px-4 py-2 text-gray-700 border-b">${recurso.subtotal.toLocaleString('es-CL')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="mt-6">
                            <h3 className="text-xl font-bold mb-4 text-gray-900">Resumen de Valores</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <p className="text-gray-900"><strong>Valor Sin IVA:</strong> ${cotizacionDetalle.valorSinIva.toLocaleString('es-CL')}</p>
                                <p className="text-gray-900"><strong>Valor Con IVA:</strong> ${cotizacionDetalle.valorConIva.toLocaleString('es-CL')}</p>
                            </div>
                        </div>

                        <div className="mt-6 text-center">
                            <p className="text-sm text-gray-600">Gracias por su preferencia. Para más información, contacte a nuestro equipo de ventas.</p>
                        </div>

                        <div className="mt-6 text-center">
                            <button
                                onClick={generarPDF}
                                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded ml-4"
                            >
                                Descargar PDF
                            </button>
                            
                                <button
                                    onClick={enviarCorreo}
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded ml-4"
                                >
                                    Enviar Correo
                                </button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};
export default Cotizacion;