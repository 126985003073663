import React, { useEffect, useState } from 'react';
import CustomAlert from '../components/CustomAlert';
import { api } from '../config/apiConfig';

const Cliente = () => {
    const [clientes, setClientes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCrearCliente, setShowCrearCliente] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const userRole = localStorage.getItem('role');
    const [formData, setFormData] = useState(initialFormData());
    const [editingId, setEditingId] = useState(null);
    const [alert, setAlert] = useState(initialAlertState());
    const darkMode = localStorage.getItem('darkMode') !== 'false';
    
    useEffect(() => {
        fetchClientes();
    }, []);

    const fetchClientes = async () => {
        try {
            const response = await api.get(`/cliente`);
            const clientesConEstado = response.data.map(cliente => ({
                ...cliente,
                estado: cliente.estado === null ? 1 : Number(cliente.estado)
            }));
            setClientes(clientesConEstado);
            setError(null);
        } catch (error) {
            setError('¡Hubo un error al obtener los clientes!');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const formatRut = (rut) => {
        let value = rut.replace(/\./g, '').replace(/-/g, '').replace(/[^0-9kK]/g, '');
        if (value.length > 1) {
            const dv = value.slice(-1);
            let numbers = value.slice(0, -1);
            if (numbers.length > 3) numbers = numbers.slice(0, -3) + '.' + numbers.slice(-3);
            if (numbers.length > 7) numbers = numbers.slice(0, -7) + '.' + numbers.slice(-7);
            value = numbers + '-' + dv;
        }
        return value;
    };

    const formatPhoneNumber = (phone) => {
        let value = phone.replace(/\D/g, '');
        if (!value.startsWith('56') && value.length >= 9) value = '56' + value;
        value = value.slice(0, 11);
        if (value.length > 2) value = '+' + value.slice(0, 2) + ' ' + value.slice(2);
        return value;
    };

    const capitalizeFirstLetter = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = formatInputValue(name, value);
        setFormData(prevState => ({ ...prevState, [name]: formattedValue }));
    };

    const formatInputValue = (name, value) => {
        switch (name) {
            case 'rut': return formatRut(value);
            case 'telefono': return formatPhoneNumber(value);
            case 'nombre':
            case 'apellido':
            case 'descripcion':
            case 'nombreEmpresa': return capitalizeFirstLetter(value);
            default: return value;
        }
    };

    const validateUniqueFields = async (formData, isEditing = false) => {
        try {
            const response = await api.get(`/cliente`);
            const existingClients = response.data;
            if (existingClients.some(client => client.rut === formData.rut && (!isEditing || client.id !== editingId))) {
                throw new Error('El RUT ya existe en el sistema');
            }
            if (existingClients.some(client => client.correo === formData.correo && (!isEditing || client.id !== editingId))) {
                throw new Error('El correo ya existe en el sistema');
            }
            return true;
        } catch (error) {
            throw error;
        }
    };

    const handleEdit = (cliente) => {
        if (cliente.estado !== 1) {
            showAlert('No se puede editar cliente inactivo', 'error');
            return;
        }
        setEditingId(cliente.id);
        setFormData({ ...cliente });
        setShowCrearCliente(true);
        setError(null);
    };

    const handleCloseAlert = () => setAlert({ ...alert, open: false });

    const showAlert = (message, severity) => setAlert({ open: true, message, severity });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingId) {
                const currentCliente = clientes.find(c => c.id === editingId);
                await validateUniqueFields(formData, true);
                await api.put(`/cliente/${editingId}`, {
                    ...formData,
                    estado: currentCliente.estado,
                    updatedAt: new Date().toISOString()
                });
                showAlert('Cliente actualizado exitosamente', 'success');
            } else {
                await validateUniqueFields(formData);
                await api.post(`/cliente`, {
                    ...formData,
                    estado: 1,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                });
                showAlert('Cliente creado exitosamente', 'success');
            }
            resetForm();
            await fetchClientes();
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || '¡Error al procesar el cliente!';
            showAlert(errorMessage, 'error');
            console.error('Error:', error);
        }
    };

    const resetForm = () => {
        setShowCrearCliente(false);
        setFormData(initialFormData());
        setEditingId(null);
    };

    const filteredClientes = clientes.filter(cliente =>
        Object.values(cliente).some(value => value?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleToggle = async (id, currentEstado) => {
        try {
            const newEstado = Number(currentEstado) === 1 ? 0 : 1;
            await api.put(`/cliente/${id}`, { 
                estado: newEstado,
                updatedAt: new Date().toISOString()
            });
            setClientes(prevClientes => 
                prevClientes.map(cliente => 
                    cliente.id === id ? { ...cliente, estado: newEstado } : cliente
                )
            );
            showAlert(`Cliente ${newEstado === 1 ? 'habilitado' : 'deshabilitado'} exitosamente`, 'success');
        } catch (error) {
            showAlert('Error al actualizar el estado del cliente', 'error');
            console.error('Error:', error);
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen text-xl font-semibold">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500 mr-3"></div>
            Cargando...
        </div>;
    }

    if (error) {
        return <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg m-4">{error}</div>;
    }

    return (
        <div className="p-16 max-w-full mx-auto text-black">
            <CustomAlert
                open={alert.open}
                message={alert.message}
                severity={alert.severity}
                onClose={handleCloseAlert}
            />
            <div className="flex justify-between items-center mb-6">
                <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                    Lista de Clientes
                </h1>
                <div className="flex space-x-4">
                    <div className="relative">
                        <input
                            type="text"
                            placeholder="Buscar cliente..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        />
                        <svg className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                        </svg>
                    </div>
                    <button 
                        onClick={() => setShowCrearCliente(true)}
                        className="w-12 h-12 bg-green-500 hover:bg-green-600 rounded-full flex items-center justify-center shadow-lg transition-all duration-300 text-white"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                        </svg>
                    </button>
                </div>
            </div>

            <div className="overflow-x-auto shadow-lg rounded-lg">
                <table className="min-w-full bg-white">
                    <thead className="bg-blue-800 text-white">
                        <tr>
                            <th className="px-4 py-3">Nombre</th>
                            <th className="px-4 py-3">Apellido</th>
                            <th className="px-4 py-3">RUT</th>
                            <th className="px-4 py-3">Descripción</th>
                            <th className="px-4 py-3">Teléfono</th>
                            <th className="px-4 py-3">Correo</th>
                            <th className="px-4 py-3">Dirección</th>
                            <th className="px-4 py-3">Nombre Empresa</th>
                            {userRole === 'Administrador' && (
                                <th className="px-4 py-3">Acciones</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {filteredClientes.length > 0 ? (
                            filteredClientes.map((cliente) => (
                                <tr key={cliente.rut} className="hover:bg-gray-100 transition-colors duration-200">
                                    <td className="border-t px-4 py-3">{cliente.nombre}</td>
                                    <td className="border-t px-4 py-3">{cliente.apellido}</td>
                                    <td className="border-t px-4 py-3">{cliente.rut}</td>
                                    <td className="border-t px-4 py-3">{cliente.descripcion}</td>
                                    <td className="border-t px-4 py-3">
                                        <a href={`tel:${cliente.telefono}`} className="text-blue-600 hover:underline">
                                            {cliente.telefono}
                                        </a>
                                    </td>
                                    <td className="border-t px-4 py-3">
                                        <a href={`mailto:${cliente.correo}`} className="text-blue-600 hover:underline">
                                            {cliente.correo}
                                        </a>
                                    </td>
                                    <td className="border-t px-4 py-3">{cliente.direccion}</td>
                                    <td className="border-t px-4 py-3">{cliente.nombreEmpresa}</td>
                                    {userRole === 'Administrador' && (
                                    <td className="border-t px-4 py-3 flex gap-2 items-center">
                                        <button 
                                            onClick={() => handleEdit(cliente)}
                                            className={`${cliente.estado === 1 ? 'bg-sky-500 hover:bg-sky-600' : 'bg-gray-400'} text-white px-4 py-2 rounded-lg transition-colors duration-300`}
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                            </svg>
                                        </button>
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input 
                                                type="checkbox" 
                                                className="sr-only peer"
                                                checked={cliente.estado === 1}
                                                onChange={() => handleToggle(cliente.id, cliente.estado)}
                                            />
                                            <div className="w-14 h-7 bg-gray-300 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-green-500"></div>
                                        </label>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" className="border-t px-4 py-8 text-center text-gray-500">
                                    {searchTerm ? 'No se encontraron resultados para la búsqueda' : 'No hay datos disponibles'}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {showCrearCliente && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-800">
                                {editingId ? 'Editar Cliente' : 'Crear Cliente'}
                            </h2>
                            <button 
                                onClick={resetForm}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        {error && (
                            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                                {error}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Nombre</label>
                                    <input
                                        type="text"
                                        name="nombre"
                                        value={formData.nombre}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                                        required
                                        placeholder="Ingrese el nombre"
                                        disabled={editingId}
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Apellido</label>
                                    <input
                                        type="text"
                                        name="apellido"
                                        value={formData.apellido}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                                        required
                                        placeholder="Ingrese el apellido"
                                        disabled={editingId}
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">RUT</label>
                                <input
                                    type="text"
                                    name="rut"
                                    value={formData.rut}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ej: 12.345.678-9"
                                    disabled={editingId}
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Descripción</label>
                                <textarea
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200 min-h-[100px]"
                                    required
                                    placeholder="Ingrese una descripción del cliente"
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Teléfono</label>
                                    <input
                                        type="tel"
                                        name="telefono"
                                        value={formData.telefono}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                                        required
                                        placeholder="+56 9 1234 5678"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Correo</label>
                                    <input
                                        type="email"
                                        name="correo"
                                        value={formData.correo}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                                        required
                                        placeholder="ejemplo@correo.com"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Dirección</label>
                                <input
                                    type="text"
                                    name="direccion"
                                    value={formData.direccion}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese la dirección completa"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Nombre Empresa</label>
                                <input
                                    type="text"
                                    name="nombreEmpresa"
                                    value={formData.nombreEmpresa}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese el nombre de la empresa"
                                />
                            </div>
                            <div className="flex justify-end space-x-4 mt-6">
                                <button
                                    type="button"
                                    onClick={resetForm}
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                    {editingId ? 'Actualizar' : 'Guardar'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

const initialFormData = () => ({
    nombre: '',
    apellido: '',
    rut: '',
    descripcion: '',
    telefono: '',
    correo: '',
    direccion: '',
    nombreEmpresa: ''
});

const initialAlertState = () => ({
    open: false,
    message: '',
    severity: 'info'
});

export default Cliente;
