import React from 'react';

const CustomAlert = ({ open, message, severity, onClose }) => {
  if (!open) return null;

  const getBackgroundColor = () => {
    switch (severity) {
      case 'error':
        return 'bg-red-500';
      case 'warning':
        return 'bg-yellow-500';
      case 'info':
        return 'bg-blue-500';
      case 'success':
        return 'bg-green-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <div 
      className="fixed top-4 right-4 z-50 min-w-[300px] max-w-[400px]"
      style={{
        animation: 'slideInRight 0.5s ease-out forwards',
      }}
    >
      <div className={`
        ${getBackgroundColor()} 
        text-white px-4 py-3 
        rounded-lg shadow-lg 
        flex justify-between items-center
      `}>
        <span className="flex-grow mr-2">{message}</span>
        <button 
          onClick={onClose}
          className="ml-4 text-white hover:text-gray-200 focus:outline-none"
        >
          ×
        </button>
      </div>
      <style>
        {`
          @keyframes slideInRight {
            from {
              transform: translateX(100%);
              opacity: 0;
            }
            to {
              transform: translateX(0);
              opacity: 1;
            }
          }
        `}
      </style>
    </div>
  );
};

export default CustomAlert;