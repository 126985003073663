import React, { useEffect, useState } from 'react';
import CustomAlert from '../components/CustomAlert';
import { api } from '../config/apiConfig';

const Usuarios = () => {
    const darkMode = localStorage.getItem('darkMode') !== 'false';
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioEditable, setUsuarioEditable] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        descripcion: '',
        rut: '',
        email: '',
        fechaNacimiento: '',
        role: '',
        estado: 1
    });

    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [passwordForm, setPasswordForm] = useState({
        userId: null,
        newPassword: '',
        confirmPassword: ''
    });

    useEffect(() => {
        const fetchUsuarios = async () => {
            setLoading(true);
            try {
                const response = await api.get(`/user`);
                const usuariosConEstado = response.data.map(user => ({
                    ...user,
                    estado: user.estado === null ? 1 : Number(user.estado)
                }));
                setUsuarios(usuariosConEstado);
            } catch (error) {
                setError('Error al cargar los usuarios. Por favor intente nuevamente.');
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsuarios();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEdit = (id) => {
        const usuario = usuarios.find(u => u.id === id);
        
        if (Number(usuario.estado) === 0) {
            showAlert('No se puede editar usuario inactivo', 'error');
            return;
        }
        
        setFormData({
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            descripcion: usuario.descripcion,
            rut: usuario.rut,
            email: usuario.email,
            fechaNacimiento: usuario.fechaNacimiento,
            role: usuario.role || '',
            estado: usuario.estado
        });
        setShowModal(true);
        setUsuarioEditable(usuario);
    };

    const handleResetPassword = (id) => {
        const usuario = usuarios.find(u => u.id === id);
        
        if (Number(usuario.estado) === 0) {
            showAlert('No se puede cambiar la contraseña de un usuario inactivo', 'error');
            return;
        }
        
        setPasswordForm({
            userId: id,
            newPassword: '',
            confirmPassword: ''
        });
        setShowPasswordModal(true);
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordForm(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (passwordForm.newPassword !== passwordForm.confirmPassword) {
            showAlert('Las contraseñas no coinciden', 'error');
            return;
        }

        try {
            await api.put(`/update-password/${passwordForm.userId}`, {
                newPassword: passwordForm.newPassword
            });

            showAlert('Contraseña actualizada exitosamente', 'success');
            setShowPasswordModal(false);
            setPasswordForm({
                userId: null,
                newPassword: '',
                confirmPassword: ''
            });
        } catch (error) {
            showAlert('Error al actualizar la contraseña', 'error');
            console.error('Error:', error);
        }
    };

    const handleToggle = async (id, currentEstado) => {
        const newEstado = Number(currentEstado) === 1 ? 0 : 1;
        try {
            await api.put(`/user/${id}`, { 
                estado: newEstado,
                updatedAt: new Date().toISOString()
            });
            
            setUsuarios(prevUsuarios => 
                prevUsuarios.map(usuario => 
                    usuario.id === id ? { ...usuario, estado: newEstado } : usuario
                )
            );
            showAlert(`Usuario ${newEstado === 1 ? 'habilitado' : 'deshabilitado'} exitosamente`, 'success');
        } catch (error) {
            showAlert('Error al actualizar el estado del usuario', 'error');
            console.error('Error actualizando estado:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (usuarioEditable) {
                const updateData = { ...formData };
                const currentUser = usuarios.find(u => u.id === usuarioEditable.id);
                
                await api.put(`/user/${usuarioEditable.id}`, {
                    ...updateData,
                    estado: currentUser.estado,
                    updatedAt: new Date().toISOString()
                });
                
                showAlert('Usuario actualizado exitosamente', 'success');
                setShowModal(false);
                setUsuarioEditable(null);
                
                const response = await api.get(`/user`);
                setUsuarios(response.data.map(user => ({
                    ...user,
                    estado: user.estado || 1
                })));
                
                resetFormData();
            } else {
                const response = await api.post(`/register`, {
                    ...formData,
                    estado: 1,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    password: '123456789'
                });

                if (response.status === 201) {
                    showAlert('Usuario creado exitosamente', 'success');
                    setShowModal(false);
                    resetFormData();
                    const updatedUsers = await api.get(`/user`);
                    setUsuarios(updatedUsers.data.map(user => ({
                        ...user,
                        estado: user.estado || 1
                    })));
                }
            }
        } catch (error) {
            showAlert(error.response?.data?.message || 'Error al guardar el usuario', 'error');
            console.error('Error:', error);
        }
    };

    const resetFormData = () => {
        setFormData({
            nombre: '',
            apellido: '',
            descripcion: '',
            rut: '',
            email: '',
            fechaNacimiento: '',
            role: '',
            estado: 1
        });
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const showAlert = (message, severity) => {
        setAlert({
            open: true,
            message,
            severity
        });
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen text-xl font-semibold">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mr-3"></div>
            Cargando...
        </div>;
    }

    if (error) {
        return <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg m-4">{error}</div>;
    }

    return (
        <div className="p-8 max-w-7xl mx-auto text-black">
            <CustomAlert
                open={alert.open}
                message={alert.message}
                severity={alert.severity}
                onClose={handleCloseAlert}
            />
            <div className="flex justify-between items-center mb-6">
                <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                    Lista de Usuarios
                </h1>
                <button 
                    onClick={() => setShowModal(true)}
                    className="w-14 h-14 rounded-full bg-green-500 hover:bg-green-600 transition-colors duration-300 text-white flex items-center justify-center shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                >
                    <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                    </svg>
                </button>
            </div>

            <div className="overflow-x-auto shadow-lg rounded-lg">
                <table className="min-w-full bg-white">
                    <thead className="bg-blue-800 text-white">
                        <tr>
                            <th className="px-4 py-3">#</th>
                            <th className="px-4 py-3">Nombre</th>
                            <th className="px-4 py-3">Apellido</th>
                            <th className="px-4 py-3">Email</th>
                            <th className="px-4 py-3">RUT</th>
                            <th className="px-4 py-3">Rol</th>
                            <th className="px-4 py-3">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {usuarios.map((usuario, index) => (
                            <tr key={usuario.id} className="hover:bg-gray-100 transition-colors duration-200">
                                <td className="border-t px-4 py-3">{index + 1}</td>
                                <td className="border-t px-4 py-3">{usuario.nombre}</td>
                                <td className="border-t px-4 py-3">{usuario.apellido}</td>
                                <td className="border-t px-4 py-3">{usuario.email}</td>
                                <td className="border-t px-4 py-3">{usuario.rut}</td>
                                <td className="border-t px-4 py-3">{usuario.role}</td>
                                <td className="border-t px-4 py-3 flex gap-2 items-center">
                                    <button 
                                        onClick={() => handleEdit(usuario.id)}
                                        className={`px-4 py-2 rounded-lg ${Number(usuario.estado) === 1 ? 'bg-cyan-500 hover:bg-cyan-600' : 'bg-gray-400'} text-white transition-colors duration-300`}
                                    >
                                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                        </svg>
                                    </button>
                                    {localStorage.getItem('role') === 'Administrador' && usuario.role !== 'Administrador' && (
                                        <button 
                                            onClick={() => handleResetPassword(usuario.id)}
                                            className={`px-4 py-2 rounded-lg ${Number(usuario.estado) === 1 ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-gray-400'} text-white transition-colors duration-300`}
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"/>
                                            </svg>
                                        </button>
                                    )}
                                    {usuario.id !== 1 && (
                                        <label className="relative inline-flex items-center cursor-pointer">
                                            <input 
                                                type="checkbox" 
                                                className="sr-only peer"
                                                checked={Number(usuario.estado) === 1}
                                                onChange={() => handleToggle(usuario.id, usuario.estado)}
                                            />
                                            <div className={`w-14 h-7 rounded-full transition-colors duration-300 ease-in-out ${Number(usuario.estado) === 1 ? 'bg-green-500' : 'bg-gray-300'}`}>
                                                <div className={`absolute w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${Number(usuario.estado) === 1 ? 'translate-x-8' : 'translate-x-1'} top-1`}></div>
                                            </div>
                                        </label>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-2xl">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-800">
                                {usuarioEditable ? 'Editar Usuario' : 'Agregar Usuario'}
                            </h2>
                            <button 
                                onClick={() => {
                                    setShowModal(false);
                                    setUsuarioEditable(null);
                                    resetFormData();
                                }}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Nombre</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    value={formData.nombre}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese el nombre"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Apellido</label>
                                <input
                                    type="text"
                                    name="apellido"
                                    value={formData.apellido}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese el apellido"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Descripción</label>
                                <textarea
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    placeholder="Ingrese una descripción"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">RUT</label>
                                <input
                                    type="text"
                                    name="rut"
                                    value={formData.rut}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese el RUT"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="ejemplo@correo.com"
                                />
                            </div>
                            {!usuarioEditable && (
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Fecha de Nacimiento</label>
                                    <input
                                        type="date"
                                        name="fechaNacimiento"
                                        value={formData.fechaNacimiento}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                        required
                                    />
                                </div>
                            )}
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Rol</label>
                                <select
                                    name="role"
                                    value={formData.role}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required={!usuarioEditable}
                                >
                                    <option value="">Seleccione un rol</option>
                                    <option value="Administrador">Administrador</option>
                                    <option value="Jefe">Jefe</option>
                                    <option value="Usuario">Usuario</option>
                                </select>
                            </div>
                            <div className="flex justify-end space-x-4 mt-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowModal(false);
                                        setUsuarioEditable(null);
                                        resetFormData();
                                    }}
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                    {usuarioEditable ? 'Actualizar' : 'Guardar'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showPasswordModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-800">
                                Restablecer Contraseña
                            </h2>
                            <button 
                                onClick={() => {
                                    setShowPasswordModal(false);
                                    setPasswordForm({
                                        userId: null,
                                        newPassword: '',
                                        confirmPassword: ''
                                    });
                                }}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <form onSubmit={handlePasswordSubmit} className="space-y-4">
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Nueva Contraseña</label>
                                <input
                                    type="password"
                                    name="newPassword"
                                    value={passwordForm.newPassword}
                                    onChange={handlePasswordChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese la nueva contraseña"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Confirmar Contraseña</label>
                                <input
                                    type="password"
                                    name="confirmPassword"
                                    value={passwordForm.confirmPassword}
                                    onChange={handlePasswordChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Confirme la nueva contraseña"
                                />
                            </div>
                            <div className="flex justify-end space-x-4 mt-6">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowPasswordModal(false);
                                        setPasswordForm({
                                            userId: null,
                                            newPassword: '',
                                            confirmPassword: ''
                                        });
                                    }}
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    Actualizar Contraseña
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Usuarios;
