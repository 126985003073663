import React, { useEffect, useState } from 'react';
import CustomAlert from '../components/CustomAlert';
import { api } from '../config/apiConfig';

const Otros = () => {
    const darkMode = localStorage.getItem('darkMode') !== 'false';

    const [state, setState] = useState({
        tiposRecurso: [],
        showCrearTipoRecurso: false,
        tipoRecursoForm: { nombre: '', descripcion: '' },
        tipoRecursoEditable: null,
        unidadesMedida: [],
        showCrearUnidadMedida: false,
        unidadMedidaForm: { nombre: '', descripcion: '' },
        unidadMedidaEditable: null,
        loading: true,
        error: null,
        alert: { open: false, message: '', severity: 'info' },
        searchTipoRecurso: '',
        searchUnidadMedida: '',
        currentPageTipoRecurso: 1,
        currentPageUnidadMedida: 1,
        itemsPerPage: 5
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [tiposResponse, unidadesResponse] = await Promise.all([
                api.get(`/tiporecurso`),
                api.get(`/unidadmedida`)
            ]);
            setState(prevState => ({
                ...prevState,
                tiposRecurso: tiposResponse.data,
                unidadesMedida: unidadesResponse.data,
                loading: false
            }));
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                alert: {
                    open: true,
                    message: 'Error al cargar los datos. Por favor, intente nuevamente.',
                    severity: 'error'
                },
                loading: false
            }));
        }
    };

    const handleTipoRecursoSubmit = async (e) => {
        e.preventDefault();
        try {
            if (state.tipoRecursoEditable) {
                const response = await api.put(`/tiporecurso/${state.tipoRecursoEditable.id}`, state.tipoRecursoForm);
                setState(prevState => ({
                    ...prevState,
                    tiposRecurso: prevState.tiposRecurso.map(tipo => tipo.id === state.tipoRecursoEditable.id ? response.data : tipo),
                    alert: { open: true, message: 'Tipo de recurso actualizado exitosamente', severity: 'success' },
                    showCrearTipoRecurso: false,
                    tipoRecursoForm: { nombre: '', descripcion: '' },
                    tipoRecursoEditable: null
                }));
            } else {
                const response = await api.post(`/tiporecurso`, state.tipoRecursoForm);
                setState(prevState => ({
                    ...prevState,
                    tiposRecurso: [...prevState.tiposRecurso, response.data],
                    alert: { open: true, message: 'Tipo de recurso creado exitosamente', severity: 'success' },
                    showCrearTipoRecurso: false,
                    tipoRecursoForm: { nombre: '', descripcion: '' },
                    tipoRecursoEditable: null
                }));
            }
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                alert: { open: true, message: 'Error al procesar la operación', severity: 'error' }
            }));
        }
    };

    const handleDeleteTipoRecurso = async (id, nombre) => {
        if (window.confirm(`¿Está seguro que desea eliminar el tipo de recurso "${nombre}"? Esta acción no se puede deshacer.`)) {
            setState(prevState => ({ ...prevState, loading: true }));
            try {
                await api.delete(`/tiporecurso/${id}`);
                setState(prevState => ({
                    ...prevState,
                    tiposRecurso: prevState.tiposRecurso.filter(tipo => tipo.id !== id),
                    alert: { open: true, message: '¡Tipo de recurso eliminado exitosamente!', severity: 'success' },
                    loading: false
                }));
            } catch (error) {
                setState(prevState => ({
                    ...prevState,
                    alert: { open: true, message: 'Error al eliminar el tipo de recurso. Es posible que esté siendo utilizado.', severity: 'error' },
                    loading: false
                }));
            }
        }
    };

    const handleUnidadMedidaSubmit = async (e) => {
        e.preventDefault();
        try {
            if (state.unidadMedidaEditable) {
                const response = await api.put(`/unidadmedida/${state.unidadMedidaEditable.id}`, state.unidadMedidaForm);
                setState(prevState => ({
                    ...prevState,
                    unidadesMedida: prevState.unidadesMedida.map(unidad => unidad.id === state.unidadMedidaEditable.id ? response.data : unidad),
                    alert: { open: true, message: 'Unidad de medida actualizada exitosamente', severity: 'success' },
                    showCrearUnidadMedida: false,
                    unidadMedidaForm: { nombre: '', descripcion: '' },
                    unidadMedidaEditable: null
                }));
            } else {
                const response = await api.post(`/unidadmedida`, state.unidadMedidaForm);
                setState(prevState => ({
                    ...prevState,
                    unidadesMedida: [...prevState.unidadesMedida, response.data],
                    alert: { open: true, message: 'Unidad de medida creada exitosamente', severity: 'success' },
                    showCrearUnidadMedida: false,
                    unidadMedidaForm: { nombre: '', descripcion: '' },
                    unidadMedidaEditable: null
                }));
            }
        } catch (error) {
            setState(prevState => ({
                ...prevState,
                alert: { open: true, message: 'Error al procesar la operación', severity: 'error' }
            }));
        }
    };

    const handleDeleteUnidadMedida = async (id, nombre) => {
        if (window.confirm(`¿Está seguro que desea eliminar la unidad de medida "${nombre}"? Esta acción no se puede deshacer.`)) {
            setState(prevState => ({ ...prevState, loading: true }));
            try {
                await api.delete(`/unidadmedida/${id}`);
                setState(prevState => ({
                    ...prevState,
                    unidadesMedida: prevState.unidadesMedida.filter(unidad => unidad.id !== id),
                    alert: { open: true, message: '¡Unidad de medida eliminada exitosamente!', severity: 'success' },
                    loading: false
                }));
            } catch (error) {
                setState(prevState => ({
                    ...prevState,
                    alert: { open: true, message: 'Error al eliminar la unidad de medida. Es posible que esté siendo utilizada.', severity: 'error' },
                    loading: false
                }));
            }
        }
    };

    const tiposRecursoFiltrados = state.tiposRecurso.filter(tipo =>
        tipo.nombre.toLowerCase().includes(state.searchTipoRecurso.toLowerCase())
    );

    const unidadesMedidaFiltradas = state.unidadesMedida.filter(unidad =>
        unidad.nombre.toLowerCase().includes(state.searchUnidadMedida.toLowerCase())
    );

    const indexOfLastTipoRecurso = state.currentPageTipoRecurso * state.itemsPerPage;
    const indexOfFirstTipoRecurso = indexOfLastTipoRecurso - state.itemsPerPage;
    const currentTiposRecurso = tiposRecursoFiltrados.slice(indexOfFirstTipoRecurso, indexOfLastTipoRecurso);

    const indexOfLastUnidadMedida = state.currentPageUnidadMedida * state.itemsPerPage;
    const indexOfFirstUnidadMedida = indexOfLastUnidadMedida - state.itemsPerPage;
    const currentUnidadesMedida = unidadesMedidaFiltradas.slice(indexOfFirstUnidadMedida, indexOfLastUnidadMedida);

    const paginate = (pageNumber, setPage) => {
        setPage(pageNumber);
    };

    if (state.loading) {
        return <div className="flex justify-center items-center h-screen text-xl font-semibold">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mr-3"></div>
            Cargando...
        </div>;
    }

    if (state.error) return (
        <div className="text-center p-4 bg-red-100 text-red-700 rounded-lg">
            {state.error}
            <button 
                onClick={fetchData}
                className="ml-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
                Reintentar
            </button>
        </div>
    );

    return (
        <div className="p-8 max-w-7xl mx-auto text-black">
            <CustomAlert
                open={state.alert.open}
                message={state.alert.message}
                severity={state.alert.severity}
                onClose={() => setState(prevState => ({ ...prevState, alert: { ...prevState.alert, open: false } }))}
            />

            {/* Sección Tipos de Recurso */}
            <div className="mb-8">
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-4">
                        <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                            Tipos de Recurso
                        </h2>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Buscar por nombre..."
                                value={state.searchTipoRecurso}
                                onChange={(e) => setState(prevState => ({ ...prevState, searchTipoRecurso: e.target.value }))}
                                className="w-64 pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out text-black"
                            />
                            <svg className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                            </svg>
                        </div>
                    </div>
                    <button
                        onClick={() => setState(prevState => ({ ...prevState, showCrearTipoRecurso: true }))}
                        className="w-14 h-14 rounded-full bg-green-500 hover:bg-green-600 transition-colors duration-300 text-white flex items-center justify-center shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                    >
                        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                        </svg>
                    </button>
                </div>

                <div className="overflow-x-auto shadow-lg rounded-lg">
                    <table className="min-w-full bg-white">
                        <thead className="bg-blue-800 text-white">
                            <tr>
                                <th className="px-4 py-3">Nombre</th>
                                <th className="px-4 py-3">Descripción</th>
                                <th className="px-4 py-3">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {currentTiposRecurso.map((tipo) => (
                                <tr key={tipo.id} className="hover:bg-gray-100 transition-colors duration-200">
                                    <td className="border-t px-4 py-3">{tipo.nombre}</td>
                                    <td className="border-t px-4 py-3">{tipo.descripcion}</td>
                                    <td className="border-t px-4 py-3 flex gap-2 items-center justify-center">
                                        <button
                                            onClick={() => setState(prevState => ({
                                                ...prevState,
                                                tipoRecursoEditable: tipo,
                                                tipoRecursoForm: { nombre: tipo.nombre, descripcion: tipo.descripcion },
                                                showCrearTipoRecurso: true
                                            }))}
                                            className="px-4 py-2 rounded-lg bg-cyan-500 hover:bg-cyan-600 text-white transition-colors duration-300"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() => handleDeleteTipoRecurso(tipo.id, tipo.nombre)}
                                            className="px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white transition-colors duration-300"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200">
                        <div className="flex justify-center flex-1">
                            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                                {Array.from({ length: Math.ceil(tiposRecursoFiltrados.length / state.itemsPerPage) }).map((_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => paginate(index + 1, (pageNumber) => setState(prevState => ({ ...prevState, currentPageTipoRecurso: pageNumber })))}
                                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                                            ${state.currentPageTipoRecurso === index + 1
                                                ? 'z-10 bg-blue-500 border-blue-500 text-white'
                                                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                                            }`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* Sección Unidades de Medida - Usar el mismo estilo */}
            <div>
                <div className="flex justify-between items-center mb-6">
                    <div className="flex items-center gap-4">
                        <h2 className={`text-2xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                            Unidades de Medida
                        </h2>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Buscar por nombre..."
                                value={state.searchUnidadMedida}
                                onChange={(e) => setState(prevState => ({ ...prevState, searchUnidadMedida: e.target.value }))}
                                className="w-64 pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out text-black"
                            />
                            <svg className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                            </svg>
                        </div>
                    </div>
                    <button
                        onClick={() => setState(prevState => ({ ...prevState, showCrearUnidadMedida: true }))}
                        className="w-14 h-14 rounded-full bg-green-500 hover:bg-green-600 transition-colors duration-300 text-white flex items-center justify-center shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                    >
                        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                        </svg>
                    </button>
                </div>

                <div className="overflow-x-auto shadow-lg rounded-lg">
                    <table className="min-w-full bg-white">
                        <thead className="bg-blue-800 text-white">
                            <tr>
                                <th className="px-4 py-3">Nombre</th>
                                <th className="px-4 py-3">Descripción</th>
                                <th className="px-4 py-3">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-700">
                            {currentUnidadesMedida.map((unidad) => (
                                <tr key={unidad.id} className="hover:bg-gray-100 transition-colors duration-200">
                                    <td className="border-t px-4 py-3">{unidad.nombre}</td>
                                    <td className="border-t px-4 py-3">{unidad.descripcion}</td>
                                    <td className="border-t px-4 py-3 flex gap-2 items-center justify-center">
                                        <button
                                            onClick={() => setState(prevState => ({
                                                ...prevState,
                                                unidadMedidaEditable: unidad,
                                                unidadMedidaForm: { nombre: unidad.nombre, descripcion: unidad.descripcion },
                                                showCrearUnidadMedida: true
                                            }))}
                                            className="px-4 py-2 rounded-lg bg-cyan-500 hover:bg-cyan-600 text-white transition-colors duration-300"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                            </svg>
                                        </button>
                                        <button
                                            onClick={() => handleDeleteUnidadMedida(unidad.id, unidad.nombre)}
                                            className="px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white transition-colors duration-300"
                                        >
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200">
                        <div className="flex justify-center flex-1">
                            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                                {Array.from({ length: Math.ceil(unidadesMedidaFiltradas.length / state.itemsPerPage) }).map((_, index) => (
                                    <button
                                        key={index}
                                        onClick={() => paginate(index + 1, (pageNumber) => setState(prevState => ({ ...prevState, currentPageUnidadMedida: pageNumber })))}
                                        className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                                            ${state.currentPageUnidadMedida === index + 1
                                                ? 'z-10 bg-blue-500 border-blue-500 text-white'
                                                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                                            }`}
                                    >
                                        {index + 1}
                                    </button>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal Tipo Recurso */}
            {state.showCrearTipoRecurso && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg w-96 max-w-[90%] max-h-[90vh] overflow-y-auto">
                        <h3 className="text-2xl font-bold mb-6 text-gray-800">
                            {state.tipoRecursoEditable ? 'Editar Tipo de Recurso' : 'Crear Tipo de Recurso'}
                        </h3>
                        <form onSubmit={handleTipoRecursoSubmit} className="space-y-6">
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Nombre</label>
                                <input
                                    type="text"
                                    value={state.tipoRecursoForm.nombre}
                                    onChange={(e) => setState(prevState => ({ ...prevState, tipoRecursoForm: { ...prevState.tipoRecursoForm, nombre: e.target.value } }))}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese el nombre del tipo de recurso"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Descripción</label>
                                <textarea
                                    value={state.tipoRecursoForm.descripcion}
                                    onChange={(e) => setState(prevState => ({ ...prevState, tipoRecursoForm: { ...prevState.tipoRecursoForm, descripcion: e.target.value } }))}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    rows="4"
                                    placeholder="Ingrese una descripción del tipo de recurso"
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    onClick={() => setState(prevState => ({
                                        ...prevState,
                                        showCrearTipoRecurso: false,
                                        tipoRecursoForm: { nombre: '', descripcion: '' },
                                        tipoRecursoEditable: null
                                    }))}
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors duration-300"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors duration-300"
                                >
                                    {state.tipoRecursoEditable ? 'Actualizar' : 'Crear'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modal Unidad Medida */}
            {state.showCrearUnidadMedida && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg w-96 max-w-[90%] max-h-[90vh] overflow-y-auto">
                        <h3 className="text-2xl font-bold mb-6 text-gray-800">
                            {state.unidadMedidaEditable ? 'Editar Unidad de Medida' : 'Crear Unidad de Medida'}
                        </h3>
                        <form onSubmit={handleUnidadMedidaSubmit} className="space-y-6">
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Nombre</label>
                                <input
                                    type="text"
                                    value={state.unidadMedidaForm.nombre}
                                    onChange={(e) => setState(prevState => ({ ...prevState, unidadMedidaForm: { ...prevState.unidadMedidaForm, nombre: e.target.value } }))}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese el nombre de la unidad de medida"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Descripción</label>
                                <textarea
                                    value={state.unidadMedidaForm.descripcion}
                                    onChange={(e) => setState(prevState => ({ ...prevState, unidadMedidaForm: { ...prevState.unidadMedidaForm, descripcion: e.target.value } }))}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    rows="4"
                                    placeholder="Ingrese una descripción de la unidad de medida"
                                />
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    type="button"
                                    onClick={() => setState(prevState => ({
                                        ...prevState,
                                        showCrearUnidadMedida: false,
                                        unidadMedidaForm: { nombre: '', descripcion: '' },
                                        unidadMedidaEditable: null
                                    }))}
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors duration-300"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors duration-300"
                                >
                                    {state.unidadMedidaEditable ? 'Actualizar' : 'Crear'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Otros;
